<div class="container-fluid">
  <h5 class="form-title">Recencement Marques concurrente à UpOwa</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="concurrentForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>1 - Quelles marques d’appareils d’éclairage utilisez-vous ?(demander à voir le produit si possible)</mat-label>
        <mat-select formControlName="deviceBrand1" (selectionChange)="checkOtherChoice($event, 1)">
          <mat-option *ngFor="let deviceBrand of deviceBrandList" [value]="deviceBrand">{{deviceBrand}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest1">
        <mat-label>1 - Autres marque d'appareils ?</mat-label>
        <input matInput formControlName="otherDeviceBrand1" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>2 - Quelles sont les différents appareils d’éclairage de cette marque avez-vous à la maison ?</mat-label>
        <mat-select multiple formControlName="deviceType2" (selectionChange)="checkOtherChoice($event, 2)">
          <mat-select-trigger>
            {{deviceType.value ? deviceType.value[0] : ''}}
            <span *ngIf="deviceType.value?.length > 1" class="example-additional-selection">
              (+{{deviceType.value.length - 1}} {{deviceType.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of deviceTypeList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest2">
        <mat-label>2 - Autres type d'appareil ?</mat-label>
        <input matInput formControlName="otherDeviceType2" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="lampNumber3">
        <mat-label>3 - Si présence de lampes, combien y’en a t-il ?</mat-label>
        <mat-select formControlName="lampNumber3">
          <mat-option *ngFor="let item of lampNumberList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>4 - L’avez-vous acheté de vous même ?</mat-label>
        <mat-select formControlName="buyYourself4">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="buyYourself.value === ouiNonList[1]">
        <mat-label>5 - Si non, il a été acheté par qui ?</mat-label>
        <mat-select formControlName="boughtByWhom5" (selectionChange)="checkOtherChoice($event, 5)">
          <mat-option *ngFor="let item of boughtByWhomList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="buyYourself.value === ouiNonList[1] && otherQuest5">
        <mat-label>5 - Autres personne ?</mat-label>
        <input matInput formControlName="otherBoughtByWhom5" type="text">
      </mat-form-field>

      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>6 - A combien a-t-il été acheté ?</mat-label>
        <mat-select formControlName="buyCost6">
          <mat-option *ngFor="let item of buyCostList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>7 - Où a t-il été acheté ?</mat-label>
        <mat-select formControlName="buyingPlace7" (selectionChange)="checkOtherChoice($event, 7)">
          <mat-option *ngFor="let item of buyingPlaceList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest7">
        <mat-label>7 - Autres endroit ?</mat-label>
        <input matInput formControlName="otherBuyingPlace7" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>8 - Etes-vous satisfait de ces appareils ?</mat-label>
        <mat-select formControlName="deviceSatisfaction8">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="deviceSatisfaction.value === ouiNonList[1]">
        <mat-label>9 - Si non pourquoi ?</mat-label>
        <mat-select multiple formControlName="whyUnsatisfied9" (selectionChange)="checkOtherChoice($event, 9)">
          <mat-select-trigger>
            {{whyUnsatisfied.value ? whyUnsatisfied.value[0] : ''}}
            <span *ngIf="whyUnsatisfied.value?.length > 1" class="example-additional-selection">
              (+{{whyUnsatisfied.value.length - 1}} {{whyUnsatisfied.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of whyUnsatisfiedList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="deviceSatisfaction.value === ouiNonList[1] && otherQuest9">
        <mat-label>9 - Autres raison ?</mat-label>
        <input matInput formControlName="otherWhyUnsatisfied9" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>10 - Quel est le type de paiement utilisé par cette marque ?</mat-label>
        <mat-select formControlName="paymentTypeUsed10">
          <mat-option *ngFor="let item of paymentTypeUsedList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="paymentTypeUsed.value === paymentTypeUsedList[1]">
        <mat-label>11 - Si paiement à crédit, en combien de mensualités les paiements doivent-ils être faits ?</mat-label>
        <mat-select formControlName="monthlyPayment11">
          <mat-option *ngFor="let item of monthlyPaymentList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>12 - Quel est le montant du premier paiement à effectuer lors de l’abonnement ?</mat-label>
        <mat-select formControlName="firstPaymentAmount12">
          <mat-option *ngFor="let item of firstPaymentList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>13 - Quel est le mode de paiement utilisé par cette marque ?</mat-label>
        <mat-select multiple formControlName="paymentModeUsed13" (selectionChange)="checkOtherChoice($event, 13)">
          <mat-select-trigger>
            {{paymentModeUsed.value ? paymentModeUsed.value[0] : ''}}
            <span *ngIf="paymentModeUsed.value?.length > 1" class="example-additional-selection">
              (+{{paymentModeUsed.value.length - 1}} {{paymentModeUsed.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of paymentModeUsedList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest13">
        <mat-label>13 - Autres mode de paiement ?</mat-label>
        <input matInput formControlName="otherPaymentModeUsed13" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>14 - En cas de souci avec votre appareil y a t-il un service à votre disposition pour gérer le problème ?</mat-label>
        <mat-select formControlName="provideSAV14">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="provideSAV.value === ouiNonList[0]">
        <mat-label>15 - Si oui, de quel service s’agit-il ?</mat-label>
        <mat-select formControlName="service15" (selectionChange)="checkOtherChoice($event, 15)">
          <mat-option *ngFor="let item of serviceList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="provideSAV.value === ouiNonList[0] && otherQuest15">
        <mat-label>15 - Autres service ?</mat-label>
        <input matInput formControlName="otherService15" type="text">
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!concurrentForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!concurrentForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
