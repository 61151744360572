<div class="container-fluid">
  <div class="question-with-border mt-2" style="text-align: center !important;">
    <span [innerHTML]="speech1"></span>
  </div>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="zoneForm" (submit)="onSubmit()">
      <div style="width: 100%">
        <mat-button-toggle-group class="form-element mx-auto" formControlName="zone" aria-label="Font Style" (change)="checkOtherChoice($event, 1)">
          <mat-button-toggle style="width: 50%; margin: 2px 10px"  [value]="zoneList[0]">ENQUÊTE RURALE</mat-button-toggle>
          <mat-button-toggle style="width: 50%; margin: 2px 10px" [value]="zoneList[1]">ENQUÊTE URBAINE</mat-button-toggle>
        </mat-button-toggle-group>
        <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      </div>

      <div *ngIf="(zone.value === zoneList[0])" formGroupName="shopVsTraders9">
        <h5 class="form-title">9 - Boutiques vs Commerciaux (ZONE RURALE)</h5>
        <div class="question-with-border">
          <span [innerHTML]="speech2"></span>
        </div>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Comment souhaitez-vous découvrir les produits UpOwa ?</mat-label>
          <mat-select formControlName="howDiscoverUpOwaProduct1">
            <mat-option *ngFor="let item of howDiscoverUpOwaProductList1" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <span>
          b. Mme Monsieur, pour terminer ce questionnaire,<br/> pouvez vous me dire à quelle distance vous habitez d'une ville/village avec des commerces qui vendent de l'électronique?<br/><br/> Pouvez-vous me rappeler le nom du village, s’il-vous-plaît ?
        </span>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>Nom du village</mat-label>
          <input matInput type="text" formControlName="villageName2">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>Distance ?</mat-label>
          <mat-select formControlName="distance3">
            <mat-option *ngFor="let item of distanceList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      </div>

      <div *ngIf="(zone.value === zoneList[1])" formGroupName="shopAndOthers10">
        <h5 class="form-title">10 - Boutiques et autres réseaux de distribution (ZONE URBAINE)</h5>
        <div class="question-with-border">
          <span [innerHTML]="speech2"></span>
        </div>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Comment souhaitez-vous découvrir les produits UpOwa ?</mat-label>
          <mat-select formControlName="howDiscoverUpOwaProduct1">
            <mat-option *ngFor="let item of howDiscoverUpOwaProductList2" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>b. Mme Monsieur, pour terminer ce questionnaire, pouvez-vous me dire si vous connaissez d’autres distributeurs de produits solaires dans votre ville ?</mat-label>
          <textarea matInput formControlName="otherSolarDistributor" style="height: 150px"></textarea>
        </mat-form-field>
        <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      </div>

      <div>
        <h5 class="form-title">11 - Lien upOwa</h5>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Etes-vous intéressé·e par un de nos produits upOwa existants ?</mat-label>
          <mat-select formControlName="upowaInterest11">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="question-with-border" *ngIf="upowaInterest.value === ouiNonList[0]">
          <span [innerHTML]="speech4"></span>
        </div>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>b. Souhaitez-vous rester informé·es sur nos activités ?</mat-label>
          <mat-select formControlName="upowaLink11">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      </div>

      <div>
        <h5 class="form-title">12 - Conclusion</h5>
        <div class="question-with-border">
          <span [innerHTML]="speech3"></span>
        </div>
      </div>
      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                color="warn"
                class="responsive-item">
          Back To List
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!zoneForm.valid">
          Save Change
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!zoneForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
