import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {v4 as uuidv4} from 'uuid';
import {LocalityData} from '../../datas/LocalityData';
import {LocalStorageService} from '../../services/local-storage.service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['./zone-form.component.css']
})
export class ZoneFormComponent implements OnInit {

  zoneForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  zoneNumber: number;
  forUpdate: boolean;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService) {
    this.forUpdate = Boolean(localStorage.getItem(ItemData.ZONE_INPROGRESS));
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getAllData(ItemData.LOCALITY).then(value => {
      console.log(value);
      if (isNotNullOrUndefined(value)) {
        this.zoneNumber = value.length;
        this.zoneForm.patchValue({surveyNumber: this.zoneNumber + 1});
      } else {
        this.zoneNumber = 0;
        this.zoneForm.patchValue({surveyNumber: this.zoneNumber + 1});
      }
      this.spinner.hide();
    });
    if (this.forUpdate) {
      this.spinner.show();
      this.pathValueInForm();
    }
  }

  initForm(): void {
    this.zoneForm = this.formBuilder.group({
        surveyNumber: [1, [Validators.required]],
        surveyDate: [new Date(), Validators.required],
        investigator: [null, Validators.required],
        investigatorPhone: [null, Validators.required]
      }
    );
  }

  private pathValueInForm(): void {
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      console.log(value);
      const updateZone = {
        surveyNumber: value.surveyNumber,
        surveyDate: value.surveyDate,
        investigator: value.investigator,
        investigatorPhone: value.investigatorPhone
      };
      this.zoneForm.patchValue(updateZone);
      this.spinner.hide();
    });
  }

  onSubmit(): void {
    this.spinner.show();
    if (this.forUpdate) {
      this.onUpdate();
    } else {
      const formValue = this.zoneForm.value;
      const zone: LocalityData = {
        code: uuidv4(),
        surveyNumber: formValue.surveyNumber,
        surveyDate: formValue.surveyDate,
        investigator: formValue.investigator,
        investigatorPhone: formValue.investigatorPhone,
        generalLocalityData: null,
        infrastructureData: null,
        principalActivity: null,
        eventLocality: null,
        referencePerson: null,
        investigatorComment35: null,
        synchronised: false,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      this.localService.addDocumentOnCollection(ItemData.LOCALITY, zone).then((value: any) => {
        const  data = value.data.data as LocalityData;
        console.log(data);
        localStorage.setItem(ItemData.CURRENT_ZONE, data.code);
        localStorage.setItem(ItemData.ZONE_COUNTER, data.surveyNumber + '');
        localStorage.setItem(ItemData.ZONE_INPROGRESS, 'true');
        // Save in the data base TODO on the end of process
        this.router.navigate(['zone', 'general-infos']);
        this.spinner.hide();
      });
    }
  }

  onUpdate(): void {
    this.spinner.show();
    const formValue = this.zoneForm.value;
    const zone = {
      surveyNumber: formValue.surveyNumber,
      surveyDate: formValue.surveyDate,
      investigator: formValue.investigator,
      investigatorPhone: formValue.investigatorPhone,
      updatedAt: new Date()
    };

    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, zone).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['zone', 'general-infos']);
    });
  }

  cancel(): void {
    this.initForm();
    if (isNotNullOrUndefined(this.currentZoneCode)) {
      this.localService.deleteDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
        console.log(value);
        localStorage.removeItem(ItemData.ZONE_INPROGRESS);
        localStorage.removeItem(ItemData.CURRENT_ZONE);
        this.router.navigate(['zone', 'list']);
        localStorage.setItem(ItemData.ZONE_COUNTER, Number(localStorage.getItem(ItemData.ZONE_COUNTER)) - 1 + '');
      });
    } else {
      localStorage.removeItem(ItemData.ZONE_INPROGRESS);
      localStorage.removeItem(ItemData.CURRENT_ZONE);
      this.router.navigate(['zone', 'list']);
    }
  }

  backToList(): void {
    this.router.navigate(['zone', 'list']);
  }
}
