import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {ItemData} from '../datas/ItemData';

@Injectable()
export class AuthGuardService {

  constructor(private route: Router,
              private authService: AuthService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        const email = localStorage.getItem(ItemData.USER.toString());
        if (email) {
          resolve(true);
        } else {
          this.route.navigate(['/']);
          resolve(false);
        }
        /*this.authService.isAuthenticated().then(
          (user) => {
            if (email) {
              resolve(true);
            } else {
              this.route.navigate(['/']);
              resolve(false);
            }
          }
        );*/
      }
    );
  }
}
