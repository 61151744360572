<div class="container-fluid">
  <h5 class="form-title">Commentaire de l'enquêteur</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="comment" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Commentaires de l’enquêteur ?</mat-label>
        <textarea matInput formControlName="investigatorComment35" style="height: 400px"></textarea>
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >
        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Back to previous step"
                matTooltipClass="example-tooltip-red"
                aria-label="Cancel"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!comment.valid">
          Save Zone
        </button>
      </div>
    </form>
  </div>
</div>
