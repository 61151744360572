<div class="container-fluid">
  <h5 class="form-title">Recencement Client UpOwa</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="customerForm" (submit)="onSubmit()">
      <div formGroupName="identification">
        <p class="mx-auto">Compléter les infos d'identification</p>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>Nom de l’acheteur ?</mat-label>
          <input matInput type="text" formControlName="buyerName">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>Numéro d’installation ?</mat-label>
          <input matInput type="text" formControlName="numInstall">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>Itinéraire ?</mat-label>
          <input matInput type="text" formControlName="itinerary">
        </mat-form-field>
      </div>
      <hr style="width: 80%; font-weight: bold">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>1 - Quel est la couleur de l’appareil à éclairage que vous avez à la maison ?</mat-label>
        <mat-select formControlName="deviceColor1">
          <mat-option *ngFor="let item of deviceColorList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>2 - Quel modèle de kit UpOwa avez-vous à la maison ?</mat-label>
        <mat-select formControlName="deviceUpowaModel2">
          <mat-option *ngFor="let item of deviceUpowaModelList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>3 - Avez-vous de la lumière chez vous ?</mat-label>
        <mat-select formControlName="haveLight3">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="haveLight.value === ouiNonList[0]">
        <mat-label>4 - Si oui, à quand remonte la date de votre dernier paiement ?</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="lastPaymentDate4">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>5 - Habituellement qui effectue vos paiements ?</mat-label>
        <mat-select formControlName="madePayment5">
          <mat-option *ngFor="let item of madePaymentList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="madePayment.value === madePaymentList[0]">
        <mat-label>6 - Si c’est effectué par le client, par quel moyen ?</mat-label>
        <mat-select multiple formControlName="paymentWay6" (selectionChange)="checkOtherChoice($event, 6)">
          <mat-select-trigger>
            {{paymentWay.value ? paymentWay.value[0] : ''}}
            <span *ngIf="paymentWay.value?.length > 1" class="example-additional-selection">
              (+{{paymentWay.value.length - 1}} {{paymentWay.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of paymentWayList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest6">
        <mat-label>6 - Autres moyen ?</mat-label>
        <input matInput formControlName="otherPaymentWay6" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="madePayment.value === madePaymentList[1]">
        <mat-label>7 - Si effectué une autre personne, pourquoi ?</mat-label>
        <mat-select multiple formControlName="whyOtherPerson7" (selectionChange)="checkOtherChoice($event, 7)">
          <mat-select-trigger>
            {{whyOtherPerson.value ? whyOtherPerson.value[0] : ''}}
            <span *ngIf="whyOtherPerson.value?.length > 1" class="example-additional-selection">
              (+{{whyOtherPerson.value.length - 1}} {{whyOtherPerson.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of whyOtherPersonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest7 && madePayment.value === madePaymentList[1]">
        <mat-label>7 - Autres raison ?</mat-label>
        <input matInput formControlName="otherWhyOtherPerson7" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="haveLight.value === ouiNonList[1]">
        <mat-label>8 - Si absence de lumière, pourquoi ?</mat-label>
        <mat-select multiple formControlName="whyNotLight8" (selectionChange)="checkOtherChoice($event, 8)">
          <mat-select-trigger>
            {{whyNotLight.value ? whyNotLight.value[0] : ''}}
            <span *ngIf="whyNotLight.value?.length > 1" class="example-additional-selection">
              (+{{whyNotLight.value.length - 1}} {{whyNotLight.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of whyNotLightList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest8">
        <mat-label>8 - Autres raison ?</mat-label>
        <input matInput formControlName="otherWhyNotLight8" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>9 - L’avez-vous acheté de vous même ou c’est un cadeau ?</mat-label>
        <mat-select formControlName="buyOrGift9">
          <mat-option *ngFor="let item of buyOrGiftList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="buyOrGift.value === buyOrGiftList[1]">
        <mat-label>10 - Si c’est un cadeau, par qui a-t-il été acheté ?</mat-label>
        <mat-select formControlName="whoBuyIt10" (selectionChange)="checkOtherChoice($event, 10)">
          <mat-option *ngFor="let item of whoBuyItList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest10">
        <mat-label>10 - Autres acheteur ? ?</mat-label>
        <input matInput formControlName="otherWhoBuyIt10" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>11 - Depuis combien de temps l’avez-vous ?</mat-label>
        <mat-select formControlName="deviceDuration11">
          <mat-option *ngFor="let item of deviceDurationList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>12 - Etes-vous satisfait de la qualité de ces appareils ?</mat-label>
        <mat-select formControlName="qualitySatisfaction12">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="qualitySatisfaction.value === ouiNonList[1]">
        <mat-label>13 - Si non pourquoi ?</mat-label>
        <textarea matInput formControlName="whyUnsatisfied13" style="height: 100px"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="qualitySatisfaction.value === ouiNonList[1]">
        <mat-label>14 - Si non, comment informez-vous UpOwa des difficultés que vous rencontrez ?</mat-label>
        <mat-select multiple formControlName="issueFeedback14" (selectionChange)="checkOtherChoice($event, 14)">
          <mat-select-trigger>
            {{issueFeedback.value ? issueFeedback.value[0] : ''}}
            <span *ngIf="issueFeedback.value?.length > 1" class="example-additional-selection">
              (+{{issueFeedback.value.length - 1}} {{issueFeedback.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of issueFeedbackList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest14">
        <mat-label>14 - Autres moyen ?</mat-label>
        <input matInput formControlName="otherIssueFeedback14" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>15 - A l’issue de votre achat seriez-vous prêt à acheter à nouveau un produit UpOwa ?</mat-label>
        <mat-select formControlName="readForOtherPurchase15">
          <mat-option *ngFor="let item of readForOtherPurchaseList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>16 - Quels autres suggestions d’équipements avez-vous besoin en dehors de ce que UpOwa vous offre déjà ?</mat-label>
        <mat-select multiple formControlName="deviceSuggestion16" (selectionChange)="checkOtherChoice($event, 16)">
          <mat-select-trigger>
            {{deviceSuggestion.value ? deviceSuggestion.value[0] : ''}}
            <span *ngIf="deviceSuggestion.value?.length > 1" class="example-additional-selection">
              (+{{deviceSuggestion.value.length - 1}} {{deviceSuggestion.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of deviceSuggestionList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest16">
        <mat-label>16 - Autres équipement ?</mat-label>
        <input matInput formControlName="otherDeviceSuggestion16" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>17 - Que souhaitez-vous nous voir améliorer chez UpOwa ?</mat-label>
        <textarea matInput formControlName="improvementSuggestion17" style="height: 100px"></textarea>
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!customerForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!customerForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
