import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {VentilatorData} from '../../../datas/VentilatorData';
import {CustomerIdentification} from '../../../datas/sub-data/CustormerIdentification';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-ventilator-customer',
  templateUrl: './ventilator-customer.component.html',
  styleUrls: ['./ventilator-customer.component.css']
})
export class VentilatorCustomerComponent implements OnInit {

  customerForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  currentVentilatorCode = localStorage.getItem(ItemData.CURRENT_VENTILATOR);

  forUpdate: boolean;
  investigatorName: any;
  speech: string;
  otherProfession: boolean;

  get profession(): any { return this.customerForm.get('profession'); }

  sexeList: string[] = ['Homme', 'Femme'];
  matrimonialSituation: string[] = ['Célibataire', 'Marié·e', 'En ménage', 'Veuf(ve)'];
  professionList: string[] = ['Agriculteur/trice', 'Eleveur', 'Artisan', 'Commerçant·e', 'Fonctionnaire',
    'Consultant·e', 'Employé·e (privé·e)', 'Ménager/ère', 'Indépendant·e', 'Retraité·e', 'Autre (préciser)'];
  incomeList: string[] = ['0 - 50 000 FCFA', '50 000 - 75 000 FCFA', '75 000 - 100 000 FCFA',
    '100 000 - 150 000 FCFA', '150 000 - 200 000 FCFA', '200 000 - 300 000 FCFA',
    '300 000 - 400 000 FCFA', '400 000 - 500 000 FCFA', '> 500 000 FCFA / mois'];

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.VENTILATOR, this.currentVentilatorCode).then((ventilator: VentilatorData) => {
      this.investigatorName = ventilator.fullName;
      this.speech = '<p>Bonjour, je suis <strong> ' + this.investigatorName + ' </strong>. <br/>Dans le cadre d’un travail de recherche, je mène une enquête sur le terrain pour le compte de l\'entreprise UpOwa. A cet effet, nous vous remercions de prendre quelques minutes de votre temps pour répondre à quelques questions qui vous seront posées. <br/>Nous vous garantissons de la confidentialité des informations données qui ne vous engage en aucun cas.</p>';
      if (isNotNullOrUndefined(ventilator.customerIdentification1)) {
        this.forUpdate = isNotNullOrUndefined(ventilator.customerIdentification1);
        this.pathValueInForm(ventilator.customerIdentification1);
      }
      this.spinner.hide();
    });
  }

  initForm(): void {
    this.customerForm = this.formBuilder.group({
        fullName: [null, Validators.required],
        age: [null, Validators.required],
        sexe: [null, Validators.required],
        phone: [null, Validators.required],
        village: [null, Validators.required],
        district: [null, Validators.required],
        department: [null, Validators.required],
        region: [null, Validators.required],
        matrimonialSituation: [null, Validators.required],
        nbChild: [null, Validators.required],
        profession: [null, Validators.required],
        otherProfession: [null],
        income: [null, Validators.required]
      }
    );
    this.spinner.hide();
  }

  private pathValueInForm(customerIdentification1: CustomerIdentification): void {
    this.customerForm.patchValue(customerIdentification1);
    this.setOtherQuestionValues(customerIdentification1);
  }


  private setOtherQuestionValues(customerIdentification1: CustomerIdentification): void {
    this.otherProfession = customerIdentification1.profession ?
      isNotNullOrUndefined(customerIdentification1.profession.filter(item =>
        item === this.professionList[(this.professionList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    const formValue = this.customerForm.value;
    const customer: CustomerIdentification = {
      fullName: formValue.fullName,
      age: formValue.age,
      sexe: formValue.sexe,
      phone: formValue.phone,
      village: formValue.village,
      district: formValue.district,
      department: formValue.department,
      region: formValue.region,
      matrimonialSituation: formValue.matrimonialSituation,
      nbChild: formValue.nbChild,
      profession: formValue.profession,
      otherProfession: formValue.otherProfession,
      income: formValue.income
    };

    this.localService.updateDocumentOnCollection(ItemData.VENTILATOR,
      this.currentVentilatorCode, {
        customerIdentification1: customer,
        updatedAt: new Date()
      }).then((value: any) => {
      this.router.navigate(['ventilator', 'usage']);
      this.spinner.hide();
    });
  }

  onBack(): void {
    this.customerForm.reset();
    this.localService.onBackClicked();
  }


  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 11:
        this.otherProfession = isNotNullOrUndefined(event.value.filter(profession =>
          profession === this.professionList[(this.professionList.length - 1)])[0]);
        break;
    }
    this.restFormControlsIfOtherUnSelected(question);
  }

  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 11:
        if (!this.otherProfession) {
          this.customerForm.setControl('otherProfession', new FormControl(null));
        }
        break;
    }
  }

}
