import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../datas/ItemData';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage.service';
import {RemoteCheckService} from '../../services/remote-check.service';
import {v4 as uuidv4} from 'uuid';
import {IdentificationMenageData} from '../../datas/IdentificationMenageData';
import {ProspectingData} from '../../datas/ProspectingData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

/**
 * Location suctom Class
 */
class Location {
  longitude?: number;
  latitude?: number;
}

/**
 * Location global variable
 */
const location: Location = new Location();

/**
 * GetPosition Function
 */
function getLocation(): void {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    console.log('Geo Location not supported by browser');
  }
}

/**
 * Show Position On Navigator
 * @param position
 */
function showPosition(position): void {
  location.longitude = position.coords.longitude;
  location.latitude = position.coords.latitude;
}

@Component({
  selector: 'app-prospection-form',
  templateUrl: './prospection-form.component.html',
  styleUrls: ['./prospection-form.component.css']
})


export class ProspectionFormComponent implements OnInit {

  prospectForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  currentProspectCode = localStorage.getItem(ItemData.CURRENT_PROSPECT);

  forUpdate: boolean;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) {
    getLocation();
    this.forUpdate = isNotNullOrUndefined(this.currentProspectCode);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    if (this.forUpdate) {
      this.spinner.show();
      this.pathValueInForm();
    }
  }

  initForm(): void {
    this.prospectForm = this.formBuilder.group({
      region: [null, Validators.required],
      department: [null, Validators.required],
      district: [null, Validators.required],
      town: [null, Validators.required],
      village: [null, Validators.required],
      investigatedName: [null, Validators.required],
      investigatedPosition: [null, Validators.required],
      contact: [null, Validators.required],
      localisation: [null]
      }
    );
    this.spinner.hide();
  }

  private pathValueInForm(): void {
    this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((value: ProspectingData) => {
      const menage = {
        region: value.identificationMenage.region,
        department: value.identificationMenage.department,
        district: value.identificationMenage.district,
        town: value.identificationMenage.town,
        village: value.identificationMenage.village,
        investigatedName: value.identificationMenage.investigatedName,
        investigatedPosition: value.identificationMenage.investigatedPosition,
        contact: value.identificationMenage.contact,
        localisation: value.identificationMenage.localisation
      };
      this.prospectForm.patchValue(menage);
      this.spinner.hide();
    });
  }

  onSubmit(): void {
    if (this.forUpdate) {
      this.onUpdate();
    } else {
      const formValue = this.prospectForm.value;
      const menage: IdentificationMenageData = {
        region: formValue.region,
        department: formValue.department,
        district: formValue.district,
        town: formValue.town,
        village: formValue.village,
        investigatedName: formValue.investigatedName,
        investigatedPosition: formValue.investigatedPosition,
        contact: formValue.contact,
        localisation: formValue.localisation
      };
      menage.longitude = location.longitude;
      menage.latitude = location.latitude;
      const prospectData: ProspectingData = {
        code: uuidv4(),
        localityCode: this.currentZoneCode,
        createdAt: new Date(),
        updatedAt: new Date(),
        identificationMenage: menage,
        form1: null,
        form2: null,
        form3: null,
        deviceBrand: null,
        deviceType: null
      };
      this.localService.addDocumentOnCollection(ItemData.PROSPECT, prospectData).then((value: any) => {
        const  data = value.data.data as ProspectingData;
        console.log(data);
        localStorage.setItem(ItemData.CURRENT_PROSPECT, data.code);
        this.router.navigate(['prospect', 'choice']);
        this.spinner.hide();
      });
    }
  }


  private onUpdate(): void {
    this.spinner.show();
    const formValue = this.prospectForm.value;
    const menage: IdentificationMenageData = {
      region: formValue.region,
      department: formValue.department,
      district: formValue.district,
      town: formValue.town,
      village: formValue.village,
      investigatedName: formValue.investigatedName,
      investigatedPosition: formValue.investigatedPosition,
      contact: formValue.contact,
      localisation: formValue.localisation
    };
    menage.longitude = location.longitude;
    menage.latitude = location.latitude;

    this.localService.updateDocumentOnCollection(ItemData.PROSPECT, this.currentProspectCode, {
      identificationMenage: menage,
      updatedAt: new Date()
    }).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['prospect', 'choice']);
    });
  }

  cancel(): void {
    this.initForm();
    localStorage.removeItem(ItemData.CURRENT_PROSPECT);
    this.router.navigate(['zone', 'detail']);
  }

  backToList(): void {
    localStorage.removeItem(ItemData.CURRENT_PROSPECT);
    this.router.navigate(['zone', 'detail']);
  }

}
