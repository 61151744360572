import {Component, OnInit} from '@angular/core';


import {DeviceDetectorService} from 'ngx-device-detector';
import firebase from 'firebase';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import {ItemData} from '../../datas/ItemData';
import {InitializerData} from '../../datas/InitializerData';
import {RemoteCheckService} from '../../services/remote-check.service';
import {LocalStorageService} from '../../services/local-storage.service';
import UserInfo = firebase.UserInfo;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  deviceInfo = null;
  isAuthenticated: boolean;

  constructor(private deviceService: DeviceDetectorService,  private router: Router,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private remoteService: RemoteCheckService, private localService: LocalStorageService) { }

  ngOnInit(): void {
    this.spinner.show();
    // Vérifier si l'utilisateur est connecté
    this.authService.isAuthenticated().then(
      (value: any) => {
        // this.isAuthenticated = true;
        this.isAuthenticated = !!value;
        this.spinner.hide();
      });
  }

  initAccountAndGetData(): void {
    this.spinner.show();
    if (localStorage.getItem(ItemData.USER.toString())) {
      /* const initData: InitializerData = {
        userEmail: 'mailoniel@emal',
        userName: 'Alex KOUASS'
      };
      this.epicFunction(initData);*/
      /*const old = [
        {
          code: '3c67b713-e721-42a9-b585-baeb762bdc04',
          surveyNumber: 2,
          surveyDate: '2021-07-28T10:58:03.722Z',
          investigator: 'Alexy Atteba',
          investigatorPhone: 656197744,
          generalLocalityData: {
            regionName1: 'Littoral',
            departmentName2: 'Wourri',
            districtName3: 'Douala 1er',
            villageName4: 'Douala',
            residentNumber5: '+ 3000',
            talkingPrincipalLanguages6: [
              'Français',
              'Anglais',
              'Autre'
            ],
            talkingLocalLanguages7: [
              'Ewondo',
              'haousa',
              'Autre'
            ]
          },
          infrastructureData: {
            stateRoute8: [
              'Route goudronnée',
              'Pistes',
              'Chemins'
            ],
            recommendedDriveWay9: [
              'Pick-up',
              'Tricycle',
              'Moto'
            ],
            transportCoastAverage10: 2000,
            packageExpeditionMode11: [
              'Pick-up',
              'Taxi de brousse',
              'Autres'
            ],
            transportLocalityFrequency12: 'à tout moment',
            adminInfrastructureState13: 'Accès difficile',
            eneo14: 'Non',
            powerCutPerWeek15: '10 - 15',
            powerCutTime16: '1H30 - 2H',
            nonElectrifiedArea17: 'Non',
            townNonElectrified18: null,
            telecomNetwork19: [
              'Orange',
              'MTN',
              'Nextell'
            ],
            telecomPoint20: [
              'Point Orange Money'
            ],
            telecomPointInfos21: [
              {
                pointNumber: 23,
                managerPoint: 'Martin Mvomo',
                phoneNumber: '656197707'
              }
            ]
          },
          principalActivity: {
            principalActivity22: [
              'Agriculture - type: maïs, arachide , coton , soja, oignon, riz, sorgho',
              'Élevage -  type: mouton, chèvre, bœuf'
            ],
            secondaryActivity23: [
              'Agriculture - type: maïs, arachide , coton , soja, oignon, riz, sorgho',
              'Élevage -  type: mouton, chèvre, bœuf',
              'Commerce - type : boissons, boivines, poissons, pièces détachées, friperies'
            ],
            harvestPeriod24: {
              culture1: [
                'Janvier',
                'Février',
                'Novembre',
                'Décembre'
              ],
              culture2: [
                'Janvier',
                'Février',
                'Octobre',
                'Novembre'
              ],
              culture3: [
                'Janvier',
                'Février',
                'Mars',
                'Décembre'
              ],
              other: [
                'Janvier',
                'Février',
                'Novembre',
                'Décembre'
              ]
            },
            foundInLocality25: [
              'Boutique d’alimentation générale',
              'Mairie',
              'Épicerie'
            ],
            numberByType26: {
              boutiqueG: '4',
              centreCente: '2',
              boutiqueAuto: '2',
              multiservices: '1',
              salonCoif: null,
              boulangerie: '3',
              ecole: null,
              groupPro: '3',
              bar: '+ 4',
              hma: '1',
              cafeResto: '3',
              institutBeaute: '2',
              autre: '2'
            },
            scholarshipChildInLocality27: '10-20%'
          },
          eventLocality: {
            isEventInLocality28: 'Oui',
            event29: [
              'Agriculture - type: maïs, arachide , coton , soja, oignon, riz, sorgho',
              'Élevage -  type: mouton, chèvre, bœuf'
            ],
            eventPeriod30: {
              festival: [
                'Janvier',
                'Février',
                'Novembre'
              ],
              foire: [
                'Janvier',
                'Février',
                'Mars'
              ],
              feteCulturelle: [
                'Mai',
                'Juin'
              ],
              jourMarche: [
                'Mars',
                'Avril',
                'Juin'
              ],
              autre: [
                'Juin',
                'Novembre',
                'Décembre'
              ]
            }
          },
          referencePerson: {
            referenceAuthority31: [
              'Imam',
              'Pasteur',
              'Prêtre'
            ],
            referenceAuthorityData32: [
              {
                function: 'Mairie',
                longName: 'Bénoué',
                phone: '670993344'
              }
            ]
          },
          investigatorComment32: 'RAS',
          synchronised: false,
          createdAt: '2021-07-28T10:58:15.023Z',
          updatedAt: '2021-07-28T11:19:05.775Z'
        },
        {
          code: '4c07e90f-b354-4ddc-8541-b89e3f5dd2f8',
          surveyNumber: 1,
          surveyDate: '2021-07-27T15:57:01.149Z',
          investigator: 'Alex KOUASSEU',
          investigatorPhone: 656446644,
          generalLocalityData: {
            regionName1: 'Centre',
            departmentName2: 'Maire Zone 2',
            districtName3: 'Mfoundi 1er',
            villageName4: 'Yaoundé 7e',
            residentNumber5: '+ 3000',
            talkingPrincipalLanguages6: [
              'Français',
              'Anglais',
              'Autre'
            ],
            talkingLocalLanguages7: [
              'Kolo',
              'haousa',
              'Autre'
            ]
          },
          infrastructureData: {
            stateRoute8: [
              'Route goudronnée',
              'Pistes',
              'Chemins'
            ],
            recommendedDriveWay9: [
              'Pick-up',
              'Tricycle',
              'Clandos',
              'Autres'
            ],
            transportCoastAverage10: 2000,
            packageExpeditionMode11: [
              'Pick-up',
              'Moto',
              'Autres'
            ],
            transportLocalityFrequency12: 'Chaque 02 jours',
            adminInfrastructureState13: 'Accès partiellement difficile',
            eneo14: 'Non',
            powerCutPerWeek15: '10 - 15',
            powerCutTime16: 'Plus de 2H',
            nonElectrifiedArea17: 'Non',
            townNonElectrified18: null,
            telecomNetwork19: [
              'Orange',
              'MTN'
            ],
            telecomPoint20: [
              'Point Orange Money'
            ],
            telecomPointInfos21: [
              {
                pointNumber: '2',
                managerPoint: 'Nom point OM Zone 2',
                phoneNumber: '656197707'
              }
            ]
          },
          principalActivity: {
            principalActivity22: [
              'Élevage -  type: mouton, chèvre, bœuf',
              'Fonctionnaire',
              'Retraité-e',
              'Autres'
            ],
            secondaryActivity23: [
              'Élevage -  type: mouton, chèvre, bœuf',
              'Commerce - type : boissons, boivines, poissons, pièces détachées, friperies',
              'Fonctionnaire'
            ],
            harvestPeriod24: {
              culture1: [
                'Janvier',
                'Mars',
                'Décembre'
              ],
              culture2: [
                'Janvier',
                'Octobre',
                'Décembre'
              ],
              culture3: [
                'Janvier',
                'Février',
                'Octobre'
              ],
              other: [
                'Janvier',
                'Février',
                'Mars'
              ]
            },
            foundInLocality25: [
              'Boutique d’alimentation générale',
              'Mairie',
              'Centre de santé/ hôpital',
              'Multiservices',
              'Salon de coiffure',
              'Boulangerie',
              'Ecole',
              'Associations',
              'Pharmacie',
              'Revendeur canal+',
              'Instituts et bureaux de microfinance',
              'Autres'
            ],
            numberByType26: {
              boutiqueG: '1',
              centreCente: null,
              boutiqueAuto: null,
              multiservices: null,
              salonCoif: null,
              boulangerie: null,
              ecole: null,
              groupPro: null,
              bar: null,
              hma: null,
              cafeResto: null,
              institutBeaute: null,
              autre: '+ 4'
            },
            scholarshipChildInLocality27: '90-100%'
          },
          eventLocality: {
            isEventInLocality28: 'Non',
            event29: [
              'Agriculture - type: maïs, arachide , coton , soja, oignon, riz, sorgho',
              'Élevage -  type: mouton, chèvre, bœuf',
              'Commerce - type : boissons, boivines, poissons, pièces détachées, friperies'
            ],
            eventPeriod30: {
              festival: null,
              foire: null,
              feteCulturelle: null,
              jourMarche: null,
              autre: null
            }
          },
          referencePerson: {
            referenceAuthority31: [
              'Maire',
              'Chef du village'
            ],
            referenceAuthorityData32: [
              {
                function: 'Maire',
                longName: 'Alin',
                phone: '653453423'
              }
            ]
          },
          investigatorComment32: 'That zone is the best',
          synchronised: true,
          createdAt: '2021-07-27T15:57:15.317Z',
          updatedAt: '2021-07-28T11:39:08.586Z'
        }
      ];
      old.forEach(value => {
        this.localService.addDocumentOnCollection(ItemData.LOCALITY, value);
      });*/
      this.router.navigate(['zone', 'new']);
      this.spinner.hide();
    } else {
      this.login() // Authentication Firebase
        .then((value: any) => {
          localStorage.setItem(ItemData.UPDATE.toString(), ItemData.VERSION.toString());
          localStorage.setItem(ItemData.ZONE_COUNTER, '0');
          this.spinner.hide();
          this.router.navigate(['zone', 'new']).then(value1 => {
            location.reload();
          });
        })
        .catch(reason => {
          console.log(reason);
          this.spinner.hide();
        });
    }
  }

  async login(): Promise<any> {
    await this.authService.googleLogin().then(
      (value: any) => {
        const user = value.user as UserInfo;
        const initData: InitializerData = {
          userEmail: user.email,
          userName: user.displayName
        };
        this.epicFunction(initData);
        this.isAuthenticated = true;
        localStorage.setItem(ItemData.USER.toString(), user.email);
      },
      reason => {
        this.isAuthenticated = false;
      }
    );
  }

  epicFunction(initData: InitializerData): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceInfo.isMobile = this.deviceService.isMobile();
    this.deviceInfo.isTablet = this.deviceService.isTablet();
    this.deviceInfo.isDesktopDevice = this.deviceService.isDesktop();
    initData.phoneModel = this.deviceInfo;
    console.log(this.deviceInfo);
    this.remoteService.genericMethodForPostRequestV1('/initializer/create', initData);
  }
}
