import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {VentilatorData} from '../../../datas/VentilatorData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {PriceEvaluation} from '../../../datas/sub-data/PriceEvaluation';
import {CashPayment} from '../../../datas/sub-data/CashPayment';
import {KitOffer} from '../../../datas/sub-data/KitOffer';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-ventilator-eval',
  templateUrl: './ventilator-eval.component.html',
  styleUrls: ['./ventilator-eval.component.css']
})
export class VentilatorEvalComponent implements OnInit {

  // Variable globale du composant
  evalForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  currentVentilatorCode = localStorage.getItem(ItemData.CURRENT_VENTILATOR);

  forUpdate: boolean;
  speech5 = 'Madame, Monsieur, permettez-moi maintenant de vous présenter les méthodes de paiement possibles pour nos produits. Vous pouvez payer nos produits petit à petit (en PAYGO), donc en payant un acompte lors de l’achat et le reste par mensualités pendant 12 à 18 mois selon le produit. Vous avez également la possibilité de payer en cash, ce qui va vous faire une réduction jusqu’à 15% sur le prix d’achat.';
  speech8 = 'a. Madame Monsieur, nous avons déjà des kits solaires à 110’000 FCFA qui incluent 3 ampoules (→ montrer sur plaquette).';
  cashPrice1: string;
  cashPrice2: string;
  cash5050: string;

  otherQuest63: boolean;

  // Accesseur des élèments du formulaire
  get wantSuchProduct(): any {return this.evalForm.get('priceEvaluation5.wantSuchProduct1'); }
  get priceInCash1(): any {return this.evalForm.get('cashPayment6.priceInCash1'); }
  get priceInCash2(): any {return this.evalForm.get('cashPayment6.priceInCash2'); }
  get priceRaison(): any {return this.evalForm.get('cashPayment6.priceRaison3'); }
  get kitOfferPlusFan(): any {return this.evalForm.get('kitOffer8.kitOfferPlusFan1'); }

  // Liste des informations du formulaire
  ouiNonList: string[] = ['Oui', 'Non'];
  wantSuchProductList: string[] = ['En cash ?', 'en plusieurs mensualités (petit à petit) ?'];
  howManyMonthList: string[] = ['Entre 2 et 6 mois', 'Entre 6 et 12 mois', 'Entre 12 et 18 mois', 'En 24 mois'];
  depositList: string[] = ['5 000', '10 000', '15 000', '20 000', '25 000', '30 000', '35 000 ou plus'];
  monthlyPaymentList: string[] = ['500', '1 000', '2 000', '3 000', '4 000', '5 000', '7 500', '10 000', '15 000', '> 15 000'];
  priceRaisonList: string[] = ['Liquidités non disponibles', 'Prix trop élevé', 'Autres'];
  maxCashPriceList: string[] = ['20 000 ', '30 000', '40 000', '50 000', '60 000', '70 000', '80 000', '90 000', '100 000', '> 100 000'];
  priceAddList: string[] = ['10 000', '20 000 ', '30 000', '40 000', '50 000', '60 000', '70 000', '80 000 ou plus'];


  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.VENTILATOR, this.currentVentilatorCode).then((ventilator: VentilatorData) => {
      if (isNotNullOrUndefined(ventilator)) {
        if (ventilator.surveyType === 'SURVEY_A') {
          this.cashPrice1 = '70 000';
          this.cashPrice2 = '68 000';
          this.cash5050 = '2 x 37 000';
        } else {
          this.cashPrice1 = '60 000';
          this.cashPrice2 = '59 000';
          this.cash5050 = '2x 32 000';
        }
        this.forUpdate = isNotNullOrUndefined(ventilator.priceEvaluation5);
        this.pathValueInForm(ventilator);
      }
      this.spinner.hide();
    });
  }

  initForm(): void {
    this.evalForm = this.formBuilder.group({
      priceEvaluation5: this.formBuilder.group({
        wantSuchProduct1: [null, Validators.required],
        howManyMonth2: [null, Validators.required],
        deposit3: [null, Validators.required],
        minMonthlyPayment4: [null, Validators.required],
        maxMonthlyPayment5: [null, Validators.required]
      }),
      cashPayment6: this.formBuilder.group({
        priceInCash1: [null],
        priceInCash2: [null],
        priceRaison3: [null],
        whyTooExpensive4: [null],
        maxCashPrice5: [null]
      }),
      cashPaymentFifty7: [null],
      kitOffer8: this.formBuilder.group({
        kitOfferPlusFan1: [null, Validators.required],
        minPriceAddFan2: [null],
        maxPriceAddFan3: [null]
      })
    });
    this.spinner.hide();
  }

  private pathValueInForm(ventilator: VentilatorData): void {
    this.evalForm.patchValue({
      cashPaymentFifty7: ventilator.cashPaymentFifty7,
      priceEvaluation5: ventilator.priceEvaluation5,
      cashPayment6: ventilator.cashPayment6,
      kitOffer8: ventilator.kitOffer8
    });
    this.setOtherQuestionValues(ventilator);
  }

  private setOtherQuestionValues(ventilator: VentilatorData): void {
    this.otherQuest63 = ventilator.cashPayment6?.priceRaison3 ?
      isNotNullOrUndefined(ventilator.cashPayment6.priceRaison3 === this.priceRaisonList[(this.priceRaisonList.length - 1)]) : false;
  }

  onSubmit(): void {
    const formValue = this.evalForm.value;
    const cash: CashPayment = formValue.cashPayment6;
    const priceEval: PriceEvaluation = formValue.priceEvaluation5;
    const kitOffer: KitOffer = formValue.kitOffer8;

    this.localService.updateDocumentOnCollection(ItemData.VENTILATOR,
      this.currentVentilatorCode, {
        cashPaymentFifty7: formValue.cashPaymentFifty7,
        priceEvaluation5: priceEval,
        cashPayment6: cash,
        kitOffer8: kitOffer,
        updatedAt: new Date()
      }).then((value: any) => {
      this.router.navigate(['ventilator', 'zone']);
      this.spinner.hide();
    });
  }

  onBack(): void {
    this.evalForm.reset();
    this.localService.onBackClicked();
  }

  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 63:
        this.otherQuest63 = event.value === this.priceRaisonList[(this.priceRaisonList.length - 1)]
          || event.value === this.priceRaisonList[(this.priceRaisonList.length - 2)];
        break;
    }
    this.restFormControlsIfOtherUnSelected(event.value, question);
  }

  restFormControlsIfOtherUnSelected(value: any, question: number): void {
    switch (question) {
      case 51: {
        if (!(value === this.wantSuchProductList[1])) {
        } else {
        }
        break;
      }
      case 61: {
        /*if (!(value === this.ouiNonList[1])) {
          this.evalForm.setControl('priceInCash2', new FormControl(null));
          this.evalForm.setControl('priceRaison3', new FormControl(null));
          this.evalForm.setControl('whyTooExpensive4', new FormControl(null));
          this.evalForm.setControl('maxCashPrice5', new FormControl(null));
          this.evalForm.setControl('cashPaymentFifty7', new FormControl(null, Validators.required));
          this.evalForm.setControl('priceRaison3', new FormControl(null, Validators.required));
        } else {
          this.evalForm.setControl('priceInCash2', new FormControl(null, Validators.required));
        }*/
        break;
      }
      case 62: {
        /*if (!(value === this.ouiNonList[1])) {
          this.evalForm.setControl('priceRaison3', new FormControl(null));
          this.evalForm.setControl('whyTooExpensive4', new FormControl(null));
          this.evalForm.setControl('maxCashPrice5', new FormControl(null));
          this.evalForm.setControl('cashPaymentFifty7', new FormControl(null, Validators.required));
        } else {
          this.evalForm.setControl('cashPaymentFifty7', new FormControl(null));
          this.evalForm.setControl('priceRaison3', new FormControl(null, Validators.required));
        }*/
        break;
      }
      case 63:
        /*if (!this.otherQuest63) {
          this.evalForm.setControl('whyTooExpensive4', new FormControl(null));
        } else {
          this.evalForm.setControl('whyTooExpensive4', new FormControl(null, Validators.required));
        }*/
        break;
    }
  }
}
