import { Injectable } from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {DialogComponent} from '../dialog/dialog.component';
import {ItemData} from '../datas/ItemData';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(private readonly updates: SwUpdate,
              public dialog: MatDialog) {
    this.updates.available.subscribe(event => {
      this.showAppUpdateAlert();
    });
  }
  showAppUpdateAlert(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: {
        response: true,
        title: 'App Update available',
        question: '<strong>Choose Ok to update</strong>'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.doAppUpdate();
      }
    });
  }
  doAppUpdate(): void {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
