import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {VentilatorData} from '../../../datas/VentilatorData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ShopVsTraders} from '../../../datas/sub-data/ShopVsTraders';
import {ShopAndOthers} from '../../../datas/sub-data/ShopAndOthers';
import {RemoteCheckService} from '../../../services/remote-check.service';

@Component({
  selector: 'app-ventilator-zone',
  templateUrl: './ventilator-zone.component.html',
  styleUrls: ['./ventilator-zone.component.css']
})
export class VentilatorZoneComponent implements OnInit {
// Variable globale du composant
  zoneForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  currentVentilatorCode = localStorage.getItem(ItemData.CURRENT_VENTILATOR);

  forUpdate: boolean;
  speech1 = 'CHOISIR ENTRE ENQUÊTE RURALE OU ENQUÊTE URBAINE';
  speech2 = 'Pour terminer Mme/Mr, <br/>Upowa souhaite vous informer que des boutiques sont en train d\'ouvrir dans différents endroits du Cameroun pour être plus proche de nos clients.';
  speech3 = 'Mr., Mme, je vous remercie pour votre temps et je vous souhaite une bonne journée.';
  speech4 = 'Donnez le Flyer et le contact upOwa à la personne enquêté';

  // Accesseur des élèments du formulaire
  get zone(): any {return this.zoneForm.get('zone'); }
  get upowaInterest(): any {return this.zoneForm.get('upowaInterest11'); }

  // Liste des informations du formulaire
  ouiNonList: string[] = ['Oui', 'Non'];
  zoneList: string[] = ['RURAL_SURVEY', 'URBAN_SURVEY'];
  distanceList: string[] = ['0 à 2 km', '3 à 5 km', '5 à 10 km', '10 à 20 km', '20 à 30 km', 'Plus de 30 km'];

  howDiscoverUpOwaProductList1: string[] = ['En boutique', 'Nos commerciaux', 'Par la radio', 'Par la TV', 'Sur internet (Site web, Facebook, Linked In, …)'];
  howDiscoverUpOwaProductList2: string[] = ['En boutique propre upOwa', 'Dans des magasins établis (Dovv, Mahima, Santa Lucia, …)', 'Nos commerciaux', 'Par la radio', 'Par la TV', 'Sur internet (Site web, Facebook, Linked In, Webshop, … )'];

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.VENTILATOR, this.currentVentilatorCode).then((ventilator: VentilatorData) => {
      if (isNotNullOrUndefined(ventilator)) {
        this.forUpdate = isNotNullOrUndefined(ventilator.priceEvaluation5);
        this.pathValueInForm(ventilator);
      }
      this.spinner.hide();
    });
  }

  initForm(): void {
    this.zoneForm = this.formBuilder.group({
      zone: [null, Validators.required],
      shopVsTraders9: this.formBuilder.group({
        howDiscoverUpOwaProduct1: [null],
        villageName2: null,
        distance3: [null]
      }),
      shopAndOthers10: this.formBuilder.group({
        howDiscoverUpOwaProduct1: [null],
        otherSolarDistributor: [null]
      }),
      upowaInterest11: [null, Validators.required],
      upowaLink11: [null, Validators.required]
    });
    this.spinner.hide();
  }

  private pathValueInForm(ventilator: VentilatorData): void {
    this.zoneForm.patchValue({
      zone: ventilator.zone,
      shopVsTraders9: ventilator.shopVsTraders9,
      shopAndOthers10: ventilator.shopAndOthers10,
      upowaInterest11: ventilator.upowaInterest11,
      upowaLink11: ventilator.upowaLink11
    });
    this.setOtherQuestionValues(ventilator);
  }

  private setOtherQuestionValues(ventilator: VentilatorData): void {

  }

  onSubmit(): void {
    const formValue = this.zoneForm.value;
    const shopVsTraders: ShopVsTraders = formValue.shopVsTraders9;
    const shopAndOthers: ShopAndOthers = formValue.shopAndOthers10;
    const date = new Date();
    const updateDate = {
      zone: formValue.zone,
      shopVsTraders9: shopVsTraders,
      shopAndOthers10: shopAndOthers,
      endHour: date.getHours() + 'H : ' + date.getMinutes() + 'M',
      updatedAt: date,
      upowaInterest11: formValue.upowaInterest11,
      upowaLink11: formValue.upowaLink11
    };
    this.localService.getOnDocumentWithCode(ItemData.VENTILATOR, this.currentVentilatorCode).then((ventilator: VentilatorData) => {
      ventilator.zone = updateDate.zone;
      ventilator.shopVsTraders9 = updateDate.shopVsTraders9;
      ventilator.shopAndOthers10 = updateDate.shopAndOthers10;
      ventilator.updatedAt = updateDate.updatedAt;
      ventilator.endHour = updateDate.endHour;
      ventilator.upowaInterest11 = updateDate.upowaInterest11;
      ventilator.upowaLink11 = updateDate.upowaLink11;
      // call update backend endpoint for the request
      this.remoteService.genericMethodForPostRequestV1('/ventilator/create', ventilator).then((value1: any) => {
        // Update data of Ventilator on the indexedDb ventilator
        this.updateVentilatorInIndexedDB(true, updateDate);
      }).catch(reason => {
        console.log(reason);
        this.updateVentilatorInIndexedDB(false, updateDate);
      });
    });

  }

  private updateVentilatorInIndexedDB(synchro: boolean, data: any): any {
    data.synchronised = synchro;
    this.localService.updateDocumentOnCollection(ItemData.VENTILATOR, this.currentVentilatorCode, data).then(updatedData => {
      console.log(updatedData);
      this.spinner.hide();
      localStorage.removeItem(ItemData.CURRENT_VENTILATOR);
      this.router.navigate(['ventilator', 'detail']);
    });
  }

  onBack(): void {
    this.zoneForm.reset();
    this.localService.onBackClicked();
  }

  checkOtherChoice(event: any, question: number): void {
    console.log(event);
    switch (question) {

    }
    this.restFormControlsIfOtherUnSelected(event.value, question);
  }

  restFormControlsIfOtherUnSelected(value: any, question: number): void {
    switch (question) {
      case 1: {
        if (value === this.zoneList[0]) {
          this.zoneForm.setControl('shopVsTraders9', this.formBuilder.group({
            howDiscoverUpOwaProduct1: [null, Validators.required],
            villageName2: null,
            distance3: [null, Validators.required]
          }));
          this.zoneForm.setControl('shopAndOthers10', this.formBuilder.group({
            howDiscoverUpOwaProduct1: [null],
            otherSolarDistributor: [null]
          }));
        } else {
          this.zoneForm.setControl('shopVsTraders9', this.formBuilder.group({
            howDiscoverUpOwaProduct1: [null],
            villageName2: null,
            distance3: [null]
          }));
          this.zoneForm.setControl('shopAndOthers10', this.formBuilder.group({
            howDiscoverUpOwaProduct1: [null, Validators.required],
            otherSolarDistributor: [null, Validators.required]
          }));
        }
        break;
      }
    }
  }

}
