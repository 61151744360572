import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalityData} from '../../../datas/LocalityData';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {

  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  comment: FormGroup;
  forUpdate: boolean;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private builder: FormBuilder,
              private spinner: NgxSpinnerService) {
    if (this.currentZoneCode && !(Boolean(localStorage.getItem(ItemData.ZONE_INPROGRESS)))) {
      localStorage.removeItem(ItemData.ZONE_INPROGRESS);
      localStorage.removeItem(ItemData.CURRENT_ZONE);
      this.router.navigate(['zone', 'new']);
    } else {
      this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
        this.forUpdate = value.referencePerson !== null;
        if (this.forUpdate && Boolean(localStorage.getItem(ItemData.ZONE_INPROGRESS))) {
          this.pathValueInForm(value.investigatorComment35);
        }
      });
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
  }

  initForm(): void {
    this.comment = this.builder.group({
      investigatorComment35: [null]
    });
    this.spinner.hide();
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.comment.value;
    console.log(formValue);
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      value.investigatorComment35 = formValue.investigatorComment35;
      this.remoteService.genericMethodForPostRequestV1('/locality/create', value).then((value1: any) => {
        console.log(value1);
        // Update data oof zone on the indexedDb locally
        this.updateZoneOnIndexDB(true, formValue.investigatorComment35);
      }).catch(reason => {
        console.log(reason);
        this.updateZoneOnIndexDB(false, formValue.investigatorComment35);
      });
    });
  }

  private updateZoneOnIndexDB(synchro: boolean, comment: string): any {
    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, {
      synchronised: synchro,
      investigatorComment35: comment,
      updatedAt: new Date()
    }).then(updatedData => {
      console.log(updatedData);
      // call update backend endpoint for the request
      localStorage.removeItem(ItemData.ZONE_INPROGRESS);
      this.spinner.hide();
      this.router.navigate(['zone', 'list']);
    });
  }

  onBack(): void {
    this.comment.reset();
    this.localService.onBackClicked();
  }

  private pathValueInForm(com: string): void {
    this.comment.patchValue({investigatorComment35: com});
  }
}
