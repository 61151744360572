<div class="container-fluid">
  <h5 class="form-title">Informations sur l'enquêteur</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="deviceTypeForm" (submit)="onSubmitDeviceType()">
      <div class="form-element p-2">
        <p class="mx-auto">1 - Quel type d’appareil/accessoires utilisez-vous pour éclairer  votre maison ?</p>
        <mat-form-field appearance="legacy"  class="form-element">
          <mat-label>Type d'appareil/accessoire</mat-label>
          <mat-select multiple formControlName="deviceType" (selectionChange)="checkDeviceTypeOption($event)">
            <mat-select-trigger>
              {{deviceType.value ? deviceType.value[0] : ''}}
              <span *ngIf="deviceType.value?.length > 1" class="example-additional-selection">
              (+{{deviceType.value.length - 1}} {{deviceType.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
            </mat-select-trigger>
            <mat-option *ngFor="let deviceType of deviceTypeList" [value]="deviceType">{{deviceType}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest1">
        <mat-label>1 - Autre type d’appareil/accessoires ?</mat-label>
        <input matInput formControlName="otherDeviceType" type="text">
      </mat-form-field>
      <div class="form-element p-2" *ngIf="goToBrandQuestion">
        <p class="mx-auto">16 - Si  usage d’un appareil à énergie solaire, quelles marques utilisez-vous ?</p>
        <mat-form-field appearance="legacy"  class="form-element">
          <mat-label>Marque utilisé</mat-label>
          <mat-select formControlName="deviceBrand">
            <mat-option *ngFor="let deviceBrand of deviceBrandList" [value]="deviceBrand">{{deviceBrand}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >
        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!deviceTypeForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!deviceTypeForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
