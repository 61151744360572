<app-spinner [text]="lordText"></app-spinner>
<mat-toolbar class="upowa-color-primary app-toolbar" style="padding: 0 3px">
  <div
       fxFlex
       fxLayoutAlign="space-between"
       fxLayoutGap="2px"
  >
    <div>
      <button mat-flat-button class="transparent-bg home-btn" (click)="goTo('zone/list')">ULand Survey</button>
    </div>

    <button *ngIf="dataToSynchronised" style="transform: scale(1.0);" matTooltip="Synchroniser les données collectées" mat-icon-button class="example-icon brightness_1-icon" aria-label="Example icon-button with brightness_1 icon" (click)="madeSync()">
      <mat-icon>sync</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <button *ngIf="!appIsUpdated" matTooltip="Mettre à jour l'application" mat-icon-button class="example-icon brightness_1-icon " (click)="updateApp()" aria-label="Example icon-button with brightness_1 icon">
      <mat-icon>update</mat-icon>
    </button>
    <button *ngIf="username" mat-raised-button class="home-btn upowa-color-last" (click)="addNewZone()">New Zone</button>
  </div>
</mat-toolbar>
<div class="parent-super">
  <router-outlet></router-outlet>
</div>
