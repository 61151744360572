import {Component, OnInit} from '@angular/core';
import {LocalityData} from '../../datas/LocalityData';
import {ProspectingData} from '../../datas/ProspectingData';
import {ItemData} from '../../datas/ItemData';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage.service';
import {RemoteCheckService} from '../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-zone-details',
  templateUrl: './zone-details.component.html',
  styleUrls: ['./zone-details.component.css']
})
export class ZoneDetailsComponent implements OnInit {

  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  prospectingData: Array<ProspectingData> = new Array<ProspectingData>();
  localityName: string;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((locality: LocalityData) => {
      this.localityName = locality.generalLocalityData.villageName4;
      this.localService.getAllData(ItemData.PROSPECT).then((prospecting: Array<ProspectingData>) => {
        this.prospectingData = prospecting.filter(value => value.localityCode === this.currentZoneCode);
      });
      this.spinner.hide();
    });
  }

  onEditProspecting(postect: ProspectingData): void {
    localStorage.setItem(ItemData.CURRENT_PROSPECT, postect.code);
    this.router.navigate(['prospect', 'new']);
  }

  onNewProspecting(): void {
    this.router.navigate(['prospect', 'new']);
  }

  goToLocalityList(): void {
    this.router.navigate(['zone', 'list']);
  }
}
