import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {AuthGuardService} from './services/auth-guard.service';
import {ZoneFormComponent} from './components/zone-form/zone-form.component';
import {InfrastructureComponent} from './components/zone-form/infrastructure/infrastructure.component';
import {GeneralInfosComponent} from './components/zone-form/general-infos/general-infos.component';
import {ActivityComponent} from './components/zone-form/activity/activity.component';
import {EventsComponent} from './components/zone-form/events/events.component';
import {CommentsComponent} from './components/zone-form/comments/comments.component';
import {ZoneListComponent} from './components/zone-form/zone-list/zone-list.component';
import {ReferencesComponent} from './components/zone-form/references/references.component';
import {ZoneDetailsComponent} from './components/zone-details/zone-details.component';
import {ProspectionFormComponent} from './components/prospection-form/prospection-form.component';
import {ChoiceFormComponent} from './components/prospection-form/choice-form/choice-form.component';
import {GeneralFormComponent} from './components/prospection-form/general-form/general-form.component';
import {ClientFormComponent} from './components/prospection-form/client-form/client-form.component';
import {ConcurrentFormComponent} from './components/prospection-form/concurrent-form/concurrent-form.component';
import {QuestionListComponent} from './components/question-list/question-list.component';
import {VantilatorDetailsComponent} from './components/vantilator/vantilator-details/vantilator-details.component';
import {VentilatorFormComponent} from './components/vantilator/ventilator-form/ventilator-form.component';
import {VentilatorCustomerComponent} from './components/vantilator/ventilator-customer/ventilator-customer.component';
import {VentilatorVentiloComponent} from './components/vantilator/ventilator-ventilo/ventilator-ventilo.component';
import {VentilatorEvalComponent} from './components/vantilator/ventilator-eval/ventilator-eval.component';
import {VentilatorZoneComponent} from './components/vantilator/ventilator-zone/ventilator-zone.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'updated', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'question', component: QuestionListComponent},
  {path: 'zone', canActivate: [AuthGuardService], children: [
      {path: 'new', component: ZoneFormComponent},
      {path: 'general-infos', component: GeneralInfosComponent},
      {path: 'infra', component: InfrastructureComponent},
      {path: 'activity', component: ActivityComponent},
      {path: 'event', component: EventsComponent},
      {path: 'reference', component: ReferencesComponent},
      {path: 'comment', component: CommentsComponent},
      {path: 'list', component: ZoneListComponent},
      {path: 'detail', component: ZoneDetailsComponent}
    ]},
  {path: 'prospect', canActivate: [AuthGuardService], children: [
      {path: 'new', component: ProspectionFormComponent},
      {path: 'new/:id', component: ProspectionFormComponent},
      {path: 'choice', component: ChoiceFormComponent},
      {path: 'general', component: GeneralFormComponent},
      {path: 'client', component: ClientFormComponent},
      {path: 'concurrent', component: ConcurrentFormComponent},
    ]},
  {path: 'ventilator', canActivate: [AuthGuardService], children: [
      {path: 'detail', component: VantilatorDetailsComponent},
      {path: 'new', component: VentilatorFormComponent},
      {path: 'customer', component: VentilatorCustomerComponent},
      {path: 'usage', component: VentilatorVentiloComponent},
      {path: 'eval', component: VentilatorEvalComponent},
      {path: 'zone', component: VentilatorZoneComponent}
    ]}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
