<div class="container-fluid">
  <h5 class="form-title">Informations générales de la localité</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="genInfosForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>1 - Nom de la région de l'enquête</mat-label>
        <input matInput formControlName="regionName1" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>2 - Nom du département de l’enquête</mat-label>
        <input matInput formControlName="departmentName2" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>3 - Nom de l’arrondissement de l’enquête</mat-label>
        <input matInput type="text" formControlName="districtName3">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>4 - Nom ville/village de l’enquête</mat-label>
        <input matInput type="text" formControlName="villageName4">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>5 - Nombre d’habitants</mat-label>
        <mat-select formControlName="residentNumber5">
          <mat-option *ngFor="let resident of residentList" [value]="resident">{{resident}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" >
        <mat-label>6 - Quelles sont les langues principales parlées dans la localité ?</mat-label>
        <mat-select multiple formControlName="talkingPrincipalLanguages6" (selectionChange)="checkOtherChoice($event, 6)">
          <mat-select-trigger>
            {{principalTalking.value ? principalTalking.value[0] : ''}}
            <span *ngIf="principalTalking.value?.length > 1" class="example-additional-selection">
          (+{{principalTalking.value.length - 1}} {{principalTalking.value?.length === 2 ? 'autre' : 'autres'}})
        </span>
          </mat-select-trigger>
          <mat-option *ngFor="let planguage of principalTalkingList" [value]="planguage">{{planguage}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherPrincipalLanguages">
        <mat-label>6 - Autres langues pricipales ?</mat-label>
        <input matInput type="text" formControlName="otherTalkingPrincipalLanguages6">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>7 - Quelles sont les langues locales parlées dans la localité?</mat-label>
        <mat-select multiple formControlName="talkingLocalLanguages7" (selectionChange)="checkOtherChoice($event, 7)">
          <mat-select-trigger>
            {{localLanguage.value ? localLanguage.value[0] : ''}}
            <span *ngIf="localLanguage.value?.length > 1" class="example-additional-selection">
          (+{{localLanguage.value.length - 1}} {{localLanguage.value?.length === 2 ? 'autre' : 'autres'}})
        </span>
          </mat-select-trigger>
          <mat-option *ngFor="let lLanguage of localLanguageList" [value]="lLanguage">{{lLanguage}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherLocaleLanguages">
        <mat-label>7 - Autres langues locale ?</mat-label>
        <input matInput type="text" formControlName="otherTalkingLocalLanguages7">
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!genInfosForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!genInfosForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
