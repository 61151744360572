<div class="parent mgs-top">
  <div class="container" style="margin-top: -50px"
       fxLayout="column"
       fxLayoutAlign="space-between"
       fxLayoutGap="30px"
       fxLayout.xs="column"
       fxLayoutGap.xs="1"
  >
    <div class=""
         fxFlex="25%"
    >
      <img src="assets/images/logo.png" class="responsive-item" alt="Photo of a Shiba Inu">
    </div>
    <div class=""
         fxFlex="50%"
    >
      <button mat-raised-button
              matTooltip="Click to initialize the APP"
              matTooltipClass="example-tooltip-red"
              aria-label="connection"
              class="example-button, upowa-color-last responsive-item"
              (click)="initAccountAndGetData()">
        Get started with ULand Survey  (Add Zone)
      </button>
    </div>
    <div class=""
         fxFlex="25%"
    >
      <p class="txt_shadow">Let's pOwer people now!</p>
      <mat-divider class="upowa-color-primary responsive-item"></mat-divider>
    </div>
  </div>
</div>
