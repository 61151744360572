import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ProspectingData} from '../../../datas/ProspectingData';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-choice-form',
  templateUrl: './choice-form.component.html',
  styleUrls: ['./choice-form.component.css']
})
export class ChoiceFormComponent implements OnInit {

  deviceTypeForm: FormGroup;
  currentProspectCode = localStorage.getItem(ItemData.CURRENT_PROSPECT);

  forUpdate: boolean;
  goToBrandQuestion: boolean;
  otherQuest1: boolean;

  deviceTypeList: string[] = ['Groupe électrogène', 'Torche artisanale', 'Torche électrique', 'Lampe à pétrole', 'Bougie',
    'Electricité ENEO', 'Appareils à énergie solaire', 'Autres'];
  deviceBrandList: string[] = ['UpOwa', 'Mpower', 'Oolu', 'Africa global Energy', 'Orange', 'Solaring',
    'D. Light', 'Canopy', 'Solarus', 'Solightech', 'Solar cameroon', 'Instrumelec Cameroun', 'Aksis Energy', 'Cameroon solar solution SARL', 'Hias corporation', 'African solar generation', 'Autres'];

  get deviceBrand(): any { return this.deviceTypeForm.get('deviceBrand'); }
  get deviceType(): any { return this.deviceTypeForm.get('deviceType'); }

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) {  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((value: ProspectingData) => {
      console.log(value);
      if (isNotNullOrUndefined(value)) {
        this.forUpdate = value.deviceType !== null;
        this.pathValueInForm();
      }
    });
  }

  initForm(): void {
    this.deviceTypeForm = this.formBuilder.group({
      deviceType: [null, Validators.required],
      otherDeviceType: [null],
      deviceBrand: [null]
      });
    this.spinner.hide();
  }

  private pathValueInForm(): void {
    this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((value: ProspectingData) => {
      console.log(value);
      this.deviceTypeForm.patchValue(
        {deviceType: value.deviceType, otherDeviceType: value.otherDeviceType, deviceBrand: value.deviceBrand}
      );
      this.checkDeviceTypePathForm(value);
      this.spinner.hide();
    });
  }

  onSubmitDeviceType(): void {
    const formValue = this.deviceTypeForm.value;
    console.log(formValue);
    this.localService.updateDocumentOnCollection(ItemData.PROSPECT, this.currentProspectCode, {
      deviceType: formValue.deviceType,
      deviceBrand: formValue.deviceBrand,
      otherDeviceType: formValue.otherDeviceType,
      updatedAt: new Date()}).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      const notSolarDevice: boolean = isNotNullOrUndefined(formValue.deviceType.filter(type => type === 'Appareils à énergie solaire')[0]);
      if (!notSolarDevice) {
        this.router.navigate(['prospect', 'general']);
      } else {
        if (formValue.deviceBrand === 'UpOwa') {
          this.router.navigate(['prospect', 'client']);
        } else {
          this.router.navigate(['prospect', 'concurrent']);
        }
      }
    });
  }

  /**
   * Activation et desactivation de la question sur le choix de la marque d'appareil solaire utilisé par le client.
   * @param event
   */
  checkDeviceTypeOption(event: MatSelectChange): void {
    console.log(event);
    // this.isOtherState = ;
    this.goToBrandQuestion = isNotNullOrUndefined(event.value.filter(type => type === 'Appareils à énergie solaire')[0]);
    if (this.goToBrandQuestion) {
      this.deviceTypeForm.setControl('deviceBrand', new FormControl(null, Validators.required));
    } else {
      this.deviceTypeForm.setControl('deviceBrand', new FormControl(null));
    }
    this.otherQuest1 = isNotNullOrUndefined(event.value.filter(principal =>
      principal === this.deviceTypeList[(this.deviceTypeList.length - 1)])[0]);
  }

  checkDeviceTypePathForm(event: ProspectingData): void {
    console.log(event);
    // this.isOtherState = ;
    if (isNotNullOrUndefined(event.deviceType)) {
      this.goToBrandQuestion = isNotNullOrUndefined(event.deviceType.filter(type => type  === 'Appareils à énergie solaire')[0]);
      if (this.goToBrandQuestion) {
        this.deviceTypeForm.setControl('deviceBrand', new FormControl(event.deviceBrand, Validators.required));
      } else {
        this.deviceTypeForm.setControl('deviceBrand', new FormControl(null));
      }
    }
  }

  onBack(): void {
    this.deviceTypeForm.reset();
    this.localService.onBackClicked();
  }
}
