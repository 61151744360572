import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ItemData} from '../../../datas/ItemData';
import {LocalityData} from '../../../datas/LocalityData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.css']
})
export class ZoneListComponent implements OnInit {

  localitiesData: Array<LocalityData> = new Array<LocalityData>();

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.localService.getAllData(ItemData.LOCALITY).then((localities: Array<LocalityData>) => {
      console.log(localities);
      if (isNotNullOrUndefined(localities)) {
        this.localitiesData = localities.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
      }
      this.spinner.hide();
    });
  }

  onEditLocality(locality: LocalityData): void {
    localStorage.setItem(ItemData.CURRENT_ZONE, locality.code);
    localStorage.setItem(ItemData.ZONE_INPROGRESS, 'true');
    this.router.navigate(['zone', 'new']);
  }

  onGoToLocalityProspectingData(locality: LocalityData): void {
    localStorage.setItem(ItemData.CURRENT_ZONE, locality.code);
    this.router.navigate(['zone', 'detail']);
  }
  onGoChooseQuestion(locality: LocalityData): void {
    localStorage.setItem(ItemData.CURRENT_ZONE, locality.code);
    this.router.navigate(['question']);
  }
}
