import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {ProspectingData} from '../../../datas/ProspectingData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {CensusGeneralData} from '../../../datas/CensusGeneralData';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.css']
})
export class GeneralFormComponent implements OnInit {

  generalForm: FormGroup;
  currentProspectCode = localStorage.getItem(ItemData.CURRENT_PROSPECT);

  forUpdate: boolean;
  useGroupElect: boolean;
  useCandles: boolean;
  useStack: boolean;
  usePetroleum: boolean;
  useEneo: boolean;
  otherQuest18: boolean;

  get havePhone(): any {return this.generalForm.get('havePhone13'); }
  get nkowUpowa(): any {return this.generalForm.get('knowUpowa17'); }
  get howKnowUpowa(): any {return this.generalForm.get('howKnowUpowa18'); }

  interestedPeriodList: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  fuelPerWeekList: string[] = ['01 litre', '02 litre', '03 litre', '04 litre', '05 litre', 'Plus de 05 litre'];
  fuelLitterCostList: string[] = ['450 FCFA', '550 FCFA', '575 FCFA', '600 FCFA', 'Plus de 600 FCFA'];
  stackUsePerWeekList: string[] = ['02', '04', '05', '06', '07', '08', 'plus de 08'];
  stackCostList: string[] = ['200 FCFA', '300 FCFA', 'Plus de 300 FCFA'];
  petroleumLitterPerWeekList: string[] = ['0.5 litre', '01 litre', '02 litre', '03 litre', 'Plus de 03 litre'];
  petroleumLitterCostList: string[] = ['400 FCFA', '450 FCFA', '500 FCFA', '550 FCFA', '600 FCFA', 'Plus de 600 FCFA'];
  candlesUsePerWeekList: string[] = ['04', '05', '06', '07', '08', 'plus de 08'];
  candlesUseCostPerWeekList: string[] = ['300 FCFA', '500 FCFA', '600 FCFA', '700 FCFA', '800 FCFA', '1000 FCFA', 'Plus de 1000 FCFA'];
  electricityCutPerWeekList: string[] = ['01', '02', '03', '04', '05', '06', 'plus de 06'];
  electricityCutDurationList: string[] = ['01h', '02h', '03h', '04h', 'Plus de 04h'];
  electricityBillCostPerMonthList: string[] = ['Moins de 2000 FCFA', '2000 FCFA', '3000 FCFA', '5000 FCFA', '8000 FCFA', '15000 FCFA', 'Plus de 15000 FCFA'];
  ouiNonList: string[] = ['Oui', 'Non'];
  howKnowUpowaList: string[] = ['Un membre de la famille', 'Un ami', 'A la radio', 'Une visite UpOwa', 'Un point de vente', 'Autre'];
  howManyPhoneList: string[] = ['01', '02', '03', '04', '05', 'plus de 05'];

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) {

  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    if (isNotNullOrUndefined(this.currentProspectCode)) {
      this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((value: ProspectingData) => {
        this.setUseBlock(value.deviceType);
        if (isNotNullOrUndefined(value.form1)) {
          this.forUpdate = value.form1 !== null;
          this.pathValueInForm(value.form1);
        }
      });
    } else {
      this.router.navigate(['prospect', 'new']);
    }
  }

  private setUseBlock(deviceType: Array<string>): void {
    const stark = ['Torche artisanale', 'Torche électrique'];
    this.useGroupElect = isNotNullOrUndefined(deviceType.filter(value => value === 'Groupe électrogène')[0]);
    this.useCandles = isNotNullOrUndefined(deviceType.filter(value => value === 'Bougie')[0]);
    this.usePetroleum = isNotNullOrUndefined(deviceType.filter(value => value === 'Lampe à pétrole')[0]);
    this.useStack = isNotNullOrUndefined(deviceType.filter(value => (value === stark[0] || value === stark[1]))[0]);
    this.useEneo = isNotNullOrUndefined(deviceType.filter(value => value === 'Electricité ENEO')[0]);
  }

  initForm(): void {
    this.generalForm = this.formBuilder.group({
      fuelPerWeek2: [null],
      fuelLitterCost3: [null],
      stackUsePerWeek4: [null],
      stackCost5: [null],
      petroleumLitterPerWeek6: [null],
      petroleumLitterCost7: [null],
      candlesUsePerWeek8: [null],
      candlesUseCostPerWeek9: [null],
      electricityCutPerWeek10: [null],
      electricityCutDuration11: [null],
      electricityBillCostPerMonth12: [null],
      havePhone13: [null, Validators.required],
      howManyPhone14: [null],
      chargingPhone15: [null],
      knowUpowa17: [null, Validators.required],
      howKnowUpowa18: [null],
      otherHowKnowUpowa18: [null],
      interestedByUpowaProduct20: [null, Validators.required],
      interestedPeriod21: [null],
      remark22: [null]
    });
    this.spinner.hide();
  }

  private pathValueInForm(form1: CensusGeneralData): void {
    this.generalForm.patchValue(form1);
    this.setOtherQuestionValues(form1);
    this.spinner.hide();
  }

  private setOtherQuestionValues(form1: CensusGeneralData): void {
    this.otherQuest18 = form1.howKnowUpowa18 ?
      isNotNullOrUndefined(form1.howKnowUpowa18.filter(item =>
        item === this.howKnowUpowaList[(this.howKnowUpowaList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.generalForm.value;
    const generalData: CensusGeneralData = {
      fuelPerWeek2: formValue.fuelPerWeek2,
      fuelLitterCost3: formValue.fuelLitterCost3,
      stackUsePerWeek4: formValue.stackUsePerWeek4,
      stackCost5: formValue.stackCost5,
      petroleumLitterPerWeek6: formValue.petroleumLitterPerWeek6,
      petroleumLitterCost7: formValue.petroleumLitterCost7,
      candlesUsePerWeek8: formValue.candlesUsePerWeek8,
      candlesUseCostPerWeek9: formValue.candlesUseCostPerWeek9,
      electricityCutPerWeek10: formValue.electricityCutPerWeek10,
      electricityCutDuration11: formValue.electricityCutDuration11,
      electricityBillCostPerMonth12: formValue.electricityBillCostPerMonth12,
      havePhone13: formValue.havePhone13,
      howManyPhone14: formValue.howManyPhone14,
      chargingPhone15: formValue.chargingPhone15,
      knowUpowa17: formValue.knowUpowa17,
      howKnowUpowa18: formValue.howKnowUpowa18,
      otherHowKnowUpowa18: formValue.otherHowKnowUpowa18,
      interestedByUpowaProduct20: formValue.interestedByUpowaProduct20,
      interestedPeriod21: formValue.interestedPeriod21,
      remark22: formValue.remark22,
    };
    const updateDate = {
      form1: generalData,
      updatedAt: new Date(),
      form2: null,
      form3: null,
      synchronised: null
    };
    this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((pros: ProspectingData) => {
      pros.form1 = updateDate.form1;
      pros.form2 = updateDate.form2;
      pros.form3 = updateDate.form3;
      pros.updatedAt = updateDate.updatedAt;
      this.remoteService.genericMethodForPostRequestV1('/prospecting/create', pros).then((value1: any) => {
        // Update data oof zone on the indexedDb locally
        this.updateProspectingInIndexedDB(true, updateDate);
      }).catch(reason => {
        console.log(reason);
        this.updateProspectingInIndexedDB(false, updateDate);
      });
    });
  }

  private updateProspectingInIndexedDB(synchro: boolean, data: any): any {
    data.synchronised = synchro;
    this.localService.updateDocumentOnCollection(ItemData.PROSPECT, this.currentProspectCode, data).then(updatedData => {
      console.log(updatedData);
      // call update backend endpoint for the request
      this.spinner.hide();
      localStorage.removeItem(ItemData.CURRENT_PROSPECT);
      this.router.navigate(['zone', 'detail']);
    });
  }

  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 18:
        this.otherQuest18 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.howKnowUpowaList[(this.howKnowUpowaList.length - 1)])[0]);
        break;
    }

    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 18:
        if (!this.otherQuest18) {
          this.generalForm.setControl('otherHowKnowUpowa18', new FormControl(null));
        }
        break;
    }
  }

  onBack(): void {
    this.generalForm.reset();
    this.localService.onBackClicked();
  }

}
