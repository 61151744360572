import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalityData} from '../../../datas/LocalityData';
import {ReferencePersonData} from '../../../datas/ReferencePersonData';
import {RefAuthorityData} from '../../../datas/sub-data/RefAuthorityData';
import {MatSelectChange} from '@angular/material/select';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {

  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  refAuthForm: FormGroup;
  forUpdate: boolean;

  get referenceAuth(): any { return this.refAuthForm.get('referenceAuthority33'); }
  get refAuthData(): any { return this.refAuthForm.controls.referenceAuthorityData34 as FormArray; }

  referenceAuthList: string[]  = ['Maire', 'Chef du village', 'Imam', 'Pasteur',
    'Prêtre', 'Commandant de Brigade', 'Préfet', ' Sous préfet', 'Autres'];
  otherQuest33: boolean;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private builder: FormBuilder,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      this.forUpdate = value.referencePerson !== null;
      if (this.forUpdate && Boolean(localStorage.getItem(ItemData.ZONE_INPROGRESS))) {
        this.pathValueInForm(value.referencePerson);
      }
    });
  }

  initForm(): void {
    this.refAuthForm = this.builder.group({
      referenceAuthority33: [null, Validators.required],
      otherReferenceAuthority33: [null],
      referenceAuthorityData34: this.builder.array([])
    });
    this.spinner.hide();
  }

  addRef(func: string, longName: string, phone: string): void {
    const refForm = this.builder.group({
      function: [func, Validators.required],
      longName: [longName, Validators.required],
      phone: [phone, Validators.required]
    });

    this.refAuthData.push(refForm);
  }

  deleteRef(refAuthIndex: number): void {
    this.refAuthData.removeAt(refAuthIndex);
  }

  private pathValueInForm(data: any): void {
    console.log(data);
    this.refAuthForm.patchValue(data);
    const refAutData = data.referenceAuthorityData34 as Array<RefAuthorityData>;
    refAutData.forEach(refA => {
      this.addRef(refA.function, refA.longName, refA.phone);
    });
    this.refAuthForm.patchValue({referenceAuthorityData34: data.referenceAuthorityData34});
    this.setOtherQuestionValues(data);
  }


  private setOtherQuestionValues(ref: ReferencePersonData): void {
    this.otherQuest33 = ref.referenceAuthority33 ?
      isNotNullOrUndefined(ref.referenceAuthority33.filter(item =>
        item === this.referenceAuthList[(this.referenceAuthList.length - 1)])[0]) : false;
  }


  onSubmit(): void {
    this.spinner.show();
    const formValue = this.refAuthForm.value;
    console.log(formValue);
    const refPerson: ReferencePersonData = {
      referenceAuthority33: formValue.referenceAuthority33,
      otherReferenceAuthority33: formValue.otherReferenceAuthority33,
      referenceAuthorityData34: formValue.referenceAuthorityData34
    };
    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, {referencePerson: refPerson}).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['zone', 'comment']);
    });
  }


  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 33:
        this.otherQuest33 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.referenceAuthList[(this.referenceAuthList.length - 1)])[0]);
        break;
    }
    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 33:
        if (!this.otherQuest33) {
          this.refAuthForm.setControl('otherReferenceAuthority33', new FormControl(null));
        }
        break;
    }
  }

  onBack(): void {
    this.refAuthForm.reset();
    this.localService.onBackClicked();
  }
}
