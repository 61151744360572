import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {VentilatorData} from '../../../datas/VentilatorData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {MatSelectChange} from '@angular/material/select';
import {VentilatorProposition} from '../../../datas/sub-data/VentilatorProposition';
import {VentilatorUsage} from '../../../datas/sub-data/VentilatorUsage';

@Component({
  selector: 'app-ventilator-ventilo',
  templateUrl: './ventilator-ventilo.component.html',
  styleUrls: ['./ventilator-ventilo.component.css']
})
export class VentilatorVentiloComponent implements OnInit {

  // Variable globale du composant
  ventiloForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  currentVentilatorCode = localStorage.getItem(ItemData.CURRENT_VENTILATOR);

  forUpdate: boolean;
  speech = 'Mme/M. nous avons constaté que la grande majorité des ventilateurs vendus sur le marché fonctionnent par l\'énergie que fournit ENEO. Cependant le manque de raccordement laisse de côté beaucoup de personnes en zones rurales.    <br/>' +
    '<br/>' +
    'Aujourd’hui, pour permettre à ses clients de profiter de leur ventilateur en autonomie, upOwa souhaite mettre sur le marché un ventilateur solaire. <br/>' +
    '<br/>' +
    'Comme nouveau produit upOwa, nous aimerions bien vous proposer un ventilateur solaire de table, que l\'on peut poser sur une table ou un tabouret, avec un diamètre de 35 cm (un gros ballon de basket) et un panneau pour le recharger. Il a 3 vitesses, un minuteur (p.Ex. s’éteint automatiquement dans la nuit au bout de 60 minutes) et le rayon de vent atteint les 5 mètres.';

  otherQuest3: boolean;
  otherQuest4: boolean;

  // Accesseur des élèments du formulaire
  get ventilator(): any {return this.ventiloForm.get('ventilator2'); }
  get whatTimeUseIt(): any {return this.ventiloForm.get('ventilatorUsage3.whatTimeUseIt2'); }
  get caseOfUse3(): any {return this.ventiloForm.get('ventilatorProposition4.caseOfUse3'); }
  get raisonOfUseFan(): any {return this.ventiloForm.get('ventilatorProposition4.raisonOfUseFan4'); }
  get whatTimeUseItElse(): any {return this.ventiloForm.get('ventilatorProposition4.whatTimeUseIt5'); }

  // Liste des informations du formulaire
  ouiNonList: string[] = ['Oui', 'Non'];
  howManyTimeList: string[] = ['Moins de 6 mois', 'Entre 6 mois et 1 an', 'Entre 1 an et 2 ans', 'Entre 2 et 3 ans', '3 ans et plus'];
  whatTimeUseItList: string[] = ['6h - 11h', '11h - 14h', '14h - 18h', '18h - minuit', 'Minuit - 6h00'];
  powerByWhatEnergyList: string[] = ['ENEO', 'Panneau solaire du foyer', 'Générateur électrique', 'Piles (rechargeables ou pas)', 'Petit champ solaire qui alimente la collectivité'];
  haveProblemList: string[] = ['Le moteur ne fonctionne pas correctement', 'Victime des coupures d\'électricité', 'Un élément du ventilateur est cassé car trop fragile', 'Aucun problème avec le ventilateur actuel'];
  casOfUseList: string[] = ['Usage domestique', 'Boutique', 'Restaurant', 'Autre usage professionnel'];
  reasonOfUseFanList: string[] = ['Pour le confort/la chaleur', 'Pour mieux dormir le soir', 'Pour chasser les moustiques', 'Pour retrouver le confort de la vie en ville', 'Autres'];

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.VENTILATOR, this.currentVentilatorCode).then((ventilator: VentilatorData) => {
      if (isNotNullOrUndefined(ventilator.ventilator2)) {
        this.forUpdate = isNotNullOrUndefined(ventilator.ventilator2);
        this.pathValueInForm(ventilator);
      }
      this.spinner.hide();
    });
  }

  initForm(): void {
    this.ventiloForm = this.formBuilder.group({
      ventilator2: [null, Validators.required],
      ventilatorUsage3: this.formBuilder.group({
        howManyTime1: [null],
        whatTimeUseIt2: [null],
        powerByWhatEnergy3: [null],
        haveProblem4: [null],
        whatToChange5: [null]
      }),
      ventilatorProposition4: this.formBuilder.group({
        readyForSolarFan1: [null, Validators.required],
        customerIsInterest2: [null, Validators.required],
        caseOfUse3: [null, Validators.required],
        otherCaseOfUse3: [null],
        raisonOfUseFan4: [null, Validators.required],
        otherRaisonOfUseFan4: [null],
        whatTimeUseIt5: [null, Validators.required]
      })}
    );
    this.spinner.hide();
  }

  private pathValueInForm(ventilator: VentilatorData): void {
    this.ventiloForm.patchValue({
      ventilator2: ventilator.ventilator2,
      ventilatorUsage3: ventilator.ventilatorUsage3,
      ventilatorProposition4: ventilator.ventilatorProposition4
    });
    this.setOtherQuestionValues(ventilator);
  }

  private setOtherQuestionValues(ventilator: VentilatorData): void {
    this.otherQuest3 = ventilator.ventilatorProposition4?.caseOfUse3 ?
      isNotNullOrUndefined(ventilator.ventilatorProposition4.caseOfUse3.filter(item =>
        item === this.casOfUseList[(this.casOfUseList.length - 1)])[0]) : false;
    this.otherQuest4 = ventilator.ventilatorProposition4?.raisonOfUseFan4 ?
      isNotNullOrUndefined(ventilator.ventilatorProposition4.raisonOfUseFan4.filter(item =>
        item === this.reasonOfUseFanList[(this.reasonOfUseFanList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    const formValue = this.ventiloForm.value;
    const usage: VentilatorUsage = formValue.ventilatorUsage3;
    const proposition: VentilatorProposition = formValue.ventilatorProposition4;

    this.localService.updateDocumentOnCollection(ItemData.VENTILATOR,
      this.currentVentilatorCode, {
        ventilator2: formValue.ventilator2,
        ventilatorUsage3: usage,
        ventilatorProposition4: proposition,
        updatedAt: new Date()
      }).then((value: any) => {
      this.router.navigate(['ventilator', 'eval']);
      this.spinner.hide();
    });
  }

  onBack(): void {
    this.ventiloForm.reset();
    this.localService.onBackClicked();
  }

  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 4.3:
        this.otherQuest3 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.casOfUseList[(this.casOfUseList.length - 1)])[0]);
        break;
      case 4.4:
        this.otherQuest4 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.reasonOfUseFanList[(this.reasonOfUseFanList.length - 1)])[0]);
        break;
    }
    this.restFormControlsIfOtherUnSelected(question);
  }

  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 1: {
        if (this.ventilator.value === this.ouiNonList[0]) {
          this.ventiloForm.setControl('ventilatorUsage3', this.formBuilder.group({
            howManyTime1: [null, Validators.required],
            whatTimeUseIt2: [null, Validators.required],
            powerByWhatEnergy3: [null, Validators.required],
            haveProblem4: [null, Validators.required],
            whatToChange5: [null]
          }));
        } else {
          this.ventiloForm.setControl('ventilatorUsage3', this.formBuilder.group({}));
        }
        break;
      }
      case 4.3:
        if (!this.otherQuest3) {
          this.ventiloForm.setControl('otherCaseOfUse3', new FormControl(null));
        }
        break;
      case 4.4:
        if (!this.otherQuest4) {
          this.ventiloForm.setControl('otherRaisonOfUseFan4', new FormControl(null));
        }
        break;
    }
  }
}
