<div class="container-fluid">
  <h5 class="form-title">Activité principale</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="activityForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>24 - Quel est l’activité principale de la localité ?</mat-label>
        <mat-select multiple formControlName="principalActivity24" (selectionChange)="checkOtherChoice($event, 24)">
          <mat-select-trigger>
            {{principalActivity.value ? principalActivity.value[0] : ''}}
            <span *ngIf="principalActivity.value?.length > 1" class="example-additional-selection">
              (+{{principalActivity.value.length - 1}} {{principalActivity.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let state of principalActivityList" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest24">
        <mat-label>24 - Autre activité principale ?</mat-label>
        <input matInput formControlName="otherPrincipalActivity24" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>25 - Quelle est l’activité secondaire de la localité ?</mat-label>
        <mat-select multiple formControlName="secondaryActivity25" (selectionChange)="checkOtherChoice($event, 25)">
          <mat-select-trigger>
            {{secondaryActivity.value ? secondaryActivity.value[0] : ''}}
            <span *ngIf="secondaryActivity.value?.length > 1" class="example-additional-selection">
          (+{{secondaryActivity.value.length - 1}} {{secondaryActivity.value?.length === 2 ? 'autre' : 'autres'}})
        </span>
          </mat-select-trigger>
          <mat-option *ngFor="let state of principalActivityList" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest25">
        <mat-label>25 - Autres moyens de locomotion ?</mat-label>
        <input matInput formControlName="otherSecondaryActivity25" type="text">
      </mat-form-field>
      <div formGroupName="harvestPeriod26">
        <p class="px-3">26 - Quelles sont les périodes de  récoltes?</p>
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Culture 1
              </mat-panel-title>
              <mat-panel-description>
                arachide, maïs, sorgho
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline"  class="form-element">
              <mat-label>Période culture 1 ?</mat-label>
              <mat-select multiple formControlName="culture1">
                <mat-select-trigger>
                  {{culture1.value ? culture1.value[0] : ''}}
                  <span *ngIf="culture1.value?.length > 1" class="example-additional-selection">
                    (+{{culture1.value.length - 1}} {{culture1.value?.length === 2 ? 'autre' : 'autres'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let period of culturePeriodList" [value]="period">{{period}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Culture 2
              </mat-panel-title>
              <mat-panel-description>
                manioc
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline"  class="form-element">
              <mat-label>Période culture 2 ?</mat-label>
              <mat-select multiple formControlName="culture2">
                <mat-select-trigger>
                  {{culture2.value ? culture2.value[0] : ''}}
                  <span *ngIf="culture2.value?.length > 1" class="example-additional-selection">
                    (+{{culture2.value.length - 1}} {{culture2.value?.length === 2 ? 'autre' : 'autres'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let period of culturePeriodList" [value]="period">{{period}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Culture 3
              </mat-panel-title>
              <mat-panel-description>
                oignon, gombo, légumes, coton
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline"  class="form-element">
              <mat-label>Période culture 3 ?</mat-label>
              <mat-select multiple formControlName="culture3">
                <mat-select-trigger>
                  {{culture3.value ? culture3.value[0] : ''}}
                  <span *ngIf="culture3.value?.length > 1" class="example-additional-selection">
                    (+{{culture3.value.length - 1}} {{culture3.value?.length === 2 ? 'autre' : 'autres'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let period of culturePeriodList" [value]="period">{{period}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Autre culture
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline"  class="form-element">
              <mat-label>Période autre culture ?</mat-label>
              <mat-select multiple formControlName="other">
                <mat-select-trigger>
                  {{other.value ? other.value[0] : ''}}
                  <span *ngIf="other.value?.length > 1" class="example-additional-selection">
                    (+{{other.value.length - 1}} {{other.value?.length === 2 ? 'autre' : 'autres'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let period of culturePeriodList" [value]="period">{{period}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <p></p>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>27 - Que trouve t-on dans la localité ?</mat-label>
        <mat-select multiple formControlName="foundInLocality27" (selectionChange)="checkOtherChoice($event, 27)">
          <mat-select-trigger>
            {{foundInLocality.value ? foundInLocality.value[0] : ''}}
            <span *ngIf="foundInLocality.value?.length > 1" class="example-additional-selection">
              (+{{foundInLocality.value.length - 1}} {{foundInLocality.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let found of foundInLocalityList" [value]="found">{{found}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest27">
        <mat-label>27 - Autre élément trouvée dans la localité ?</mat-label>
        <input matInput formControlName="otherFoundInLocality27" type="text">
      </mat-form-field>

      <div formGroupName="numberByType28">
        <p class="px-3">28 - Quel est le nombre de?</p>
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel *ngFor="let numb of numberOfTypeList">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{numb.text}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline"  class="form-element">
              <mat-label>{{numb.text}}</mat-label>
              <mat-select [formControlName]="numb.name">
                <mat-option *ngFor="let numOf of numberOfList" [value]="numOf">{{numOf}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <p></p>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>29 - Quel est le pourcentage d’enfants de moins de 14 ans qui sont scolarisés dans cette localité ?</mat-label>
        <mat-select formControlName="scholarshipChildInLocality29">
          <mat-option *ngFor="let percentOfChild of percentOfChildList" [value]="percentOfChild">{{percentOfChild}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >
        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!activityForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!activityForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
