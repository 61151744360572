import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css']
})
export class QuestionListComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToProspectingData(): void {
    this.router.navigate(['zone', 'detail']);
  }

  goToVentilatorData(): void {
    this.router.navigate(['ventilator', 'detail']);
  }
}
