import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {ProspectingData} from '../../../datas/ProspectingData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {CensusClientData} from '../../../datas/CensusClientData';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css']
})
export class ClientFormComponent implements OnInit {

  customerForm: FormGroup;
  currentProspectCode = localStorage.getItem(ItemData.CURRENT_PROSPECT);

  forUpdate: boolean;

  get haveLight(): any {return this.customerForm.get('haveLight3'); }
  get madePayment(): any {return this.customerForm.get('madePayment5'); }
  get paymentWay(): any {return this.customerForm.get('paymentWay6'); }
  get whyOtherPerson(): any {return this.customerForm.get('whyOtherPerson7'); }
  get whyNotLight(): any {return this.customerForm.get('whyNotLight8'); }
  get buyOrGift(): any {return this.customerForm.get('buyOrGift9'); }
  get qualitySatisfaction(): any {return this.customerForm.get('qualitySatisfaction12'); }
  get issueFeedback(): any {return this.customerForm.get('issueFeedback14'); }
  get deviceSuggestion(): any {return this.customerForm.get('deviceSuggestion16'); }

  ouiNonList: string[] = ['Oui', 'Non'];
  deviceUpowaModelList: string[] = ['03 lampes, 01 batterie, 01 panneau solaire, 01 chargeur de téléphone',
    '04 lampes, 01 batterie, 01 panneau solaire, 01 chargeur de téléphone, 01 torche rechargeable',
    '04 lampes, 02 batteries, 01 chargeur de téléphone, 01 torche rechargeable, 01 TV'];
  madePaymentList: string[] = ['Vous même', 'Autres personne'];
  deviceColorList: string[] = ['Jaune', 'Vert', 'Orange', 'Bleu', 'Gris'];
  paymentWayList: string[] = ['OM', 'MTN Mobile Money', 'Un commercial', 'Autres'];
  whyOtherPersonList: string[] = ['Absence de compte OM/MTN money', 'Ignore le procédé de paiement', 'Le commercial de la localité s’en charge',
    'Absence de point OM/MTN money à proximité', 'Autres'];
  whyNotLightList: string[] = ['Faute de moyens pour continuer à payer', 'Ignore où faire le paiement', 'Lieu de paiement très éloigné du domicile',
    'Aucun commercial n’est plus passé dans la zone depuis longtemps', 'A effectué le paiement mais n’a pas reçu de lumière', 'Appareil défectueux', 'Autres'];
  buyOrGiftList: string[] = ['Moi même', 'Un cadeau'];
  whoBuyItList: string[] = ['Enfants vivant en zone urbaine', 'Don d’une campagne', 'Autres'];
  deviceDurationList: string[] = ['Moins d\'un mois', '2 à 04 mois', '06 mois', 'un and et plus'];
  issueFeedbackList: string[] = ['Parrain/ambassadeur', 'Équipe terrain(commercial, technicien )', 'Contact centre',
    'Boutique/évènement commercial', 'Autres'];
  readForOtherPurchaseList: string[] = ['Oui, certainement', 'Oui, peut-être', 'Probablement pas',
    'certainement pas', 'Ne sais pas'];
  deviceSuggestionList: string[] = ['Ventilateur', 'Frigo', 'Congélateur', 'Pompe à eau', 'Tondeuses', 'station de recharge téléphones', 'Autres'];
  // Variables pour afficher les zone de saisie pour les question disposant du choie 'autre'
  otherQuest6: boolean;
  otherQuest7: boolean;
  otherQuest8: boolean;
  otherQuest10: boolean;
  otherQuest14: boolean;
  otherQuest16: boolean;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    if (isNotNullOrUndefined(this.currentProspectCode)) {
      this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((value: ProspectingData) => {
        console.log(value);
        if (isNotNullOrUndefined(value.form2)) {
          this.forUpdate = value.form2 !== null;
          this.pathValueInForm(value.form2);
        }
      });
    } else {
      this.router.navigate(['prospect', 'new']);
    }
  }

  initForm(): void {
    this.customerForm = this.formBuilder.group({
      identification: this.formBuilder.group({
        buyerName: [null, Validators.required],
        itinerary: [null, Validators.required],
        numInstall: [null, Validators.required]
      }),
      deviceColor1: [null, Validators.required],
      deviceUpowaModel2: [null, Validators.required],
      haveLight3: [null, Validators.required],
      lastPaymentDate4: [null],
      madePayment5: [null, Validators.required],
      paymentWay6: [null],
      otherPaymentWay6: [null],
      whyOtherPerson7: [null],
      otherWhyOtherPerson7: [null],
      whyNotLight8: [null],
      otherWhyNotLight8: [null],
      buyOrGift9: [null, Validators.required],
      whoBuyIt10: [null],
      otherWhoBuyIt10: [null],
      deviceDuration11: [null, Validators.required],
      qualitySatisfaction12: [null, Validators.required],
      whyUnsatisfied13: [null],
      issueFeedback14: [null],
      otherIssueFeedback14: [null],
      readForOtherPurchase15: [null, Validators.required],
      deviceSuggestion16: [null, Validators.required],
      otherDeviceSuggestion16: [null],
      improvementSuggestion17: [null]
    });
    this.spinner.hide();
  }

  private pathValueInForm(form2: CensusClientData): void {
    this.customerForm.patchValue(form2);
    this.setOtherQuestionValues(form2);
    this.spinner.hide();
  }

  private setOtherQuestionValues(form2: CensusClientData): void {
    this.otherQuest6 = form2.paymentWay6 ? isNotNullOrUndefined(form2.paymentWay6.filter(item =>
      item === this.paymentWayList[(this.paymentWayList.length - 1)])[0]) : false;
    this.otherQuest7 = form2.whyOtherPerson7 ? isNotNullOrUndefined(form2.whyOtherPerson7.filter(item =>
      item === this.whyOtherPersonList[(this.whyOtherPersonList.length - 1)])[0]) : false;
    this.otherQuest8 = form2.whyNotLight8 ? isNotNullOrUndefined(form2.whyNotLight8.filter(item =>
      item === this.whyNotLightList[(this.whyNotLightList.length - 1)])[0]) : false;
    this.otherQuest10 = form2.whoBuyIt10 ? isNotNullOrUndefined(
      form2.whoBuyIt10 === this.whoBuyItList[(this.whoBuyItList.length - 1)]) : false;
    this.otherQuest14 = form2.issueFeedback14 ? isNotNullOrUndefined(form2.issueFeedback14.filter(item =>
      item === this.issueFeedbackList[(this.issueFeedbackList.length - 1)])[0]) : false;
    this.otherQuest16 = form2.deviceSuggestion16 ? isNotNullOrUndefined(form2.deviceSuggestion16.filter(item =>
      item === this.deviceSuggestionList[(this.deviceSuggestionList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.customerForm.value;
    console.log(formValue);
    const clientData: CensusClientData = {
      identification: formValue.identification,
      deviceColor1: formValue.deviceColor1,
      deviceUpowaModel2: formValue.deviceUpowaModel2,
      haveLight3: formValue.haveLight3,
      lastPaymentDate4: formValue.lastPaymentDate4,
      madePayment5: formValue.madePayment5,
      paymentWay6: formValue.paymentWay6,
      otherPaymentWay6: formValue.otherPaymentWay6,
      whyOtherPerson7: formValue.whyOtherPerson7,
      otherWhyOtherPerson7: formValue.otherWhyOtherPerson7,
      whyNotLight8: formValue.whyNotLight8,
      otherWhyNotLight8: formValue.otherWhyNotLight8,
      buyOrGift9: formValue.buyOrGift9,
      whoBuyIt10: formValue.whoBuyIt10,
      otherWhoBuyIt10: formValue.otherWhoBuyIt10,
      deviceDuration11: formValue.deviceDuration11,
      qualitySatisfaction12: formValue.qualitySatisfaction12,
      whyUnsatisfied13: formValue.whyUnsatisfied13,
      issueFeedback14: formValue.issueFeedback14,
      otherIssueFeedback14: formValue.otherIssueFeedback14,
      readForOtherPurchase15: formValue.readForOtherPurchase15,
      deviceSuggestion16: formValue.deviceSuggestion16,
      otherDeviceSuggestion16: formValue.otherDeviceSuggestion16,
      improvementSuggestion17: formValue.improvementSuggestion17
    };

    const updateDate = {
      form1: null,
      updatedAt: new Date(),
      form2: clientData,
      form3: null,
      synchronised: null
    };

    this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((pros: ProspectingData) => {
      pros.form1 = updateDate.form1;
      pros.form2 = updateDate.form2;
      pros.form3 = updateDate.form3;
      pros.updatedAt = updateDate.updatedAt;
      this.remoteService.genericMethodForPostRequestV1('/prospecting/create', pros).then((value1: any) => {
        // Update data oof zone on the indexedDb locally
        this.updateProspectingInIndexedDB(true, updateDate);
      }).catch(reason => {
        console.log(reason);
        this.updateProspectingInIndexedDB(false, updateDate);
      });
    });
  }


  private updateProspectingInIndexedDB(synchro: boolean, data: any): any {
    data.synchronised = synchro;
    this.localService.updateDocumentOnCollection(ItemData.PROSPECT, this.currentProspectCode, data).then(updatedData => {
      console.log(updatedData);
      // call update backend endpoint for the request
      this.spinner.hide();
      localStorage.removeItem(ItemData.CURRENT_PROSPECT);
      this.router.navigate(['zone', 'detail']);
    });
  }


  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 6:
        this.otherQuest6 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.paymentWayList[(this.paymentWayList.length - 1)])[0]);
        break;
      case 7:
        this.otherQuest7 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.whyOtherPersonList[(this.whyOtherPersonList.length - 1)])[0]);
        break;
      case 8:
        this.otherQuest8 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.whyNotLightList[(this.whyNotLightList.length - 1)])[0]);
        break;
      case 10:
        this.otherQuest10 = event.value === this.whoBuyItList[(this.whoBuyItList.length - 1)];
        break;
      case 14:
        this.otherQuest14 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.issueFeedbackList[(this.issueFeedbackList.length - 1)])[0]);
        break;
      case 16:
        this.otherQuest16 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.deviceSuggestionList[(this.deviceSuggestionList.length - 1)])[0]);
        break;
    }

    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 6:
        if (!this.otherQuest6) {
          this.customerForm.setControl('otherPaymentWay6', new FormControl(null));
        }
        break;
      case 7:
        if (!this.otherQuest7) {
          this.customerForm.setControl('otherWhyOtherPerson7', new FormControl(null));
        }
        break;
      case 8:
        if (!this.otherQuest8) {
          this.customerForm.setControl('otherWhyNotLight8', new FormControl(null));
        }
        break;
      case 10:
        if (!this.otherQuest10) {
          this.customerForm.setControl('otherWhoBuyIt10', new FormControl(null));
        }
        break;
      case 14:
        if (!this.otherQuest14) {
          this.customerForm.setControl('otherIssueFeedback14', new FormControl(null));
        }
        break;
      case 16:
        if (!this.otherQuest16) {
          this.customerForm.setControl('otherDeviceSuggestion16', new FormControl(null));
        }
        break;
    }
  }

  onBack(): void {
    this.customerForm.reset();
    this.localService.onBackClicked();
  }

}
