<div class="container-fluid">
  <h5 class="form-title">Informations sur l'Infrastructure</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="infraForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>8 - Quel est l’état des voies d’accès à la localité ?</mat-label>
        <mat-select multiple formControlName="stateRoute8" (selectionChange)="checkOtherChoice($event, 8)">
          <mat-select-trigger>
            {{stateRoute.value ? stateRoute.value[0] : ''}}
            <span *ngIf="stateRoute.value?.length > 1" class="example-additional-selection">
              (+{{stateRoute.value.length - 1}} {{stateRoute.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let state of stateRouteList" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest8">
        <mat-label>8 - Autres état des voies ?</mat-label>
        <input matInput formControlName="otherStateRoute8" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>9 - Quels sont les Moyens de locomotion recommandé ?</mat-label>
        <mat-select multiple formControlName="recommendedDriveWay9" (selectionChange)="checkOtherChoice($event, 9)">
          <mat-select-trigger>
            {{recommendedDrive.value ? recommendedDrive.value[0] : ''}}
            <span *ngIf="recommendedDrive.value?.length > 1" class="example-additional-selection">
              (+{{recommendedDrive.value.length - 1}} {{recommendedDrive.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let drive of recommendedDriveList" [value]="drive">{{drive}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest9">
        <mat-label>9 - Autres moyens de locomotion ?</mat-label>
        <input matInput formControlName="otherRecommendedDriveWay9" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>10 - Quel est le coût moyen du transport partant du centre ville vers une localité ?</mat-label>
        <input matInput formControlName="transportCoastAverage10" type="number">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>11 - Quel est le mode d’expédition d’un colis dans la localité ?</mat-label>
        <mat-select multiple formControlName="packageExpeditionMode11" (selectionChange)="checkOtherChoice($event, 11)">
          <mat-select-trigger>
            {{expeditionMode.value ? expeditionMode.value[0] : ''}}
            <span *ngIf="expeditionMode.value?.length > 1" class="example-additional-selection">
              (+{{expeditionMode.value.length - 1}} {{expeditionMode.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let expedition of packageExpeditionList" [value]="expedition">{{expedition}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest11">
        <mat-label>11 - Autres mode d'expédition ?</mat-label>
        <input matInput formControlName="otherPackageExpeditionMode11" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>12 - A quelle fréquence ces moyens de transport passent-ils dans la localité ?</mat-label>
        <mat-select formControlName="transportLocalityFrequency12" (selectionChange)="checkOtherChoice($event, 12)">
          <mat-option *ngFor="let transportF of transportFrequencyList" [value]="transportF">{{transportF}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest12">
        <mat-label>12 - Autre fréquence de moyens de transport ?</mat-label>
        <input matInput formControlName="otherTransportLocalityFrequency12" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>13 - Quel est l’état des infrastructures Administratives ?</mat-label>
        <mat-select formControlName="adminInfrastructureState13">
          <mat-option *ngFor="let adminInfraState of adminInfraStateList" [value]="adminInfraState">{{adminInfraState}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>14 - La localité est-elle électrifiée par ENEO ?</mat-label>
        <mat-select formControlName="eneo14">
          <mat-option *ngFor="let eneo of eneoList" [value]="eneo">{{eneo}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>15 - Quel est le nombre moyen de coupure d’électricité par semaine ?</mat-label>
        <mat-select formControlName="powerCutPerWeek15">
          <mat-option *ngFor="let power of powerCutList" [value]="power">{{power}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>16 - Quel est le temps de coupure ?</mat-label>
        <mat-select formControlName="powerCutTime16">
          <mat-option *ngFor="let power of powerCutTimeList" [value]="power">{{power}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>17 - Y a-t-il des zones non électrifiées aux alentours ?</mat-label>
        <mat-select formControlName="nonElectrifiedArea17">
          <mat-option *ngFor="let elect of zoneElectList" [value]="elect">{{elect}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="nonElectArea.value === 'Oui'">
        <mat-label>18 - Si oui, quels sont les noms des villages/villes alentour sans électricité ?</mat-label>
        <input matInput formControlName="townNonElectrified18" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>19 - Quels réseaux mobiles telecom couvre la zone ?</mat-label>
        <mat-select multiple formControlName="telecomNetwork19">
          <mat-select-trigger>
            {{telecomNetwork.value ? telecomNetwork.value[0] : ''}}
            <span *ngIf="telecomNetwork.value?.length > 1" class="example-additional-selection">
          (+{{telecomNetwork.value.length - 1}} {{telecomNetwork.value?.length === 2 ? 'autre' : 'autres'}})
        </span>
          </mat-select-trigger>
          <mat-option *ngFor="let network of telecomNetworkList" [value]="network">{{network}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>20 - Quelle est la qualité du réseau mobile télécom qui couvre la zone?(un seul choix possible ?</mat-label>
        <mat-select formControlName="telecomNetWorkQuality20">
          <mat-option *ngFor="let quality of networkQualityList" [value]="quality">{{quality}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>21 - Quels sont les points télécom présents dans la localité ?</mat-label>
        <mat-select multiple formControlName="telecomPoint21" (selectionChange)="checkOtherOption($event)">
          <mat-select-trigger>
            {{telecomPoint.value ? telecomPoint.value[0] : ''}}
            <span *ngIf="telecomPoint.value?.length > 1" class="example-additional-selection">
          (+{{telecomPoint.value.length - 1}} {{telecomPoint.value?.length === 2 ? 'autre' : 'autres'}})
        </span>
          </mat-select-trigger>
          <mat-option *ngFor="let point of telecomPointList" [value]="point">{{point}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="telecomPointOtrIsSelected">
        <mat-label>21 - Autres point télécom ?</mat-label>
        <input matInput formControlName="otherTelecomPoint21" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>22 - Quel est le réseau de distribution des produits solaires dans la localité ?</mat-label>
        <mat-select multiple formControlName="distributionNetwork22" (selectionChange)="checkOtherChoice($event, 22)">
          <mat-select-trigger>
            {{distributionNetwork.value ? distributionNetwork.value[0] : ''}}
            <span *ngIf="distributionNetwork.value?.length > 1" class="example-additional-selection">
              (+{{distributionNetwork.value.length - 1}} {{distributionNetwork.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let distribution of networkDistributionList" [value]="distribution">{{distribution}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest22">
        <mat-label>22 - Autres réseau de distribution ?</mat-label>
        <input matInput formControlName="otherDistributionNetwork22" type="text">
      </mat-form-field>
      <div *ngIf="telecomPointOrgIsSelected || telecomPointMtnIsSelected || telecomPointNxtIsSelected || telecomPointOtrIsSelected" formGroupName="telecomPointInfos23">
        <p class="mx-auto">23 - Pour chaque point coché, renseigner les cases suivantes</p>
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel *ngIf="telecomPointOrgIsSelected" formGroupName="telecomPointOrg">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Point
              </mat-panel-title>
              <mat-panel-description>
                Orange Money
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nombre de point</mat-label>
              <input type="number" matInput formControlName="pointNumber">
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nom du gestionnaire</mat-label>
              <input matInput formControlName="managerPoint">
            </mat-form-field >
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>N° Téléphone</mat-label>
              <input matInput type="number" formControlName="phoneNumber">
            </mat-form-field>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="telecomPointMtnIsSelected" formGroupName="telecomPointMtn">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Point
              </mat-panel-title>
              <mat-panel-description>
                MTN Mobile Money
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nombre de point</mat-label>
              <input matInput type="number" formControlName="pointNumber">
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nom du gestionnaire</mat-label>
              <input matInput formControlName="managerPoint">
            </mat-form-field >
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>N° Téléphone</mat-label>
              <input matInput type="number" formControlName="phoneNumber">
            </mat-form-field>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="telecomPointNxtIsSelected" formGroupName="telecomPointNxt">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Point
              </mat-panel-title>
              <mat-panel-description>
                Express Union
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nombre de point</mat-label>
              <input matInput type="number" formControlName="pointNumber">
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nom du gestionnaire</mat-label>
              <input matInput formControlName="managerPoint">
            </mat-form-field >
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>N° Téléphone</mat-label>
              <input matInput type="number" formControlName="phoneNumber">
            </mat-form-field>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="telecomPointOtrIsSelected" formGroupName="telecomPointOtr">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Autre
              </mat-panel-title>
              <mat-panel-description>
                Point
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nombre de point</mat-label>
              <input matInput type="number" formControlName="pointNumber">
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>Nom du gestionnaire</mat-label>
              <input matInput formControlName="managerPoint">
            </mat-form-field >
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>N° Téléphone</mat-label>
              <input matInput type="number" formControlName="phoneNumber">
            </mat-form-field>
          </mat-expansion-panel>

        </mat-accordion>

      </div>


      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >
        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Back to previous step"
                matTooltipClass="example-tooltip-red"
                aria-label="Cancel"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!infraForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!infraForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
