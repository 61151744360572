import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {TelecomPointData} from '../../../datas/sub-data/TelecomPointData';
import {MatSelectChange} from '@angular/material/select';
import {InfrastructureData} from '../../../datas/InfrastructureData';
import {LocalityData} from '../../../datas/LocalityData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.css']
})
export class InfrastructureComponent implements OnInit {
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  infraForm: FormGroup;
  forUpdate: boolean;

  get stateRoute(): any { return this.infraForm.get('stateRoute8'); }
  get recommendedDrive(): any { return this.infraForm.get('recommendedDriveWay9'); }
  get expeditionMode(): any { return this.infraForm.get('packageExpeditionMode11'); }
  get transportFrequency(): any { return this.infraForm.get('transportLocalityFrequency12'); }
  get nonElectArea(): any { return this.infraForm.get('nonElectrifiedArea17'); }
  get telecomNetwork(): any { return this.infraForm.get('telecomNetwork19'); }
  get telecomPoint(): any { return this.infraForm.get('telecomPoint21'); }
  get distributionNetwork(): any { return this.infraForm.get('distributionNetwork22'); }

  stateRouteList: string[] = ['Route goudronnée', 'Pistes', 'Chemins', 'Rivière', 'Ponts en bois',
    'Bacs', 'Ponts de pierres', 'Route accessible par voiture', 'Route en latérite', 'Route en terre rouge',
    'Route accessible avec un tricycle', 'Autres'];
  recommendedDriveList: string[] = ['Pick-up', 'Tricycle', 'Moto', 'Tous Les moyens', 'Clandos', 'Autres'];
  packageExpeditionList: string[] = ['Pick-up', 'Taxi de brousse', 'Moto', 'Clandos', 'Autres'];
  transportFrequencyList: string[] = ['01 fois/jour', '01 fois/semaine', 'Chaque 02 jours', 'à tout moment', '02 fois/semaine', 'Autres'];
  adminInfraStateList: string[] = ['Accès facile', 'Accès difficile', 'Accès partiellement difficile'];
  eneoList: string[] = ['Oui', 'Non', 'Partiellement'];
  powerCutList: string[] = ['1 - 5', '5 - 10', '10 - 15', '15 et plus'];
  powerCutTimeList: string[] = ['- de 30 min', '30 min - 1 H', '1H - 1H30', '1H30 - 2H', 'Plus de 2H'];
  zoneElectList: string[] = ['Oui', 'Non'];
  telecomNetworkList: string[] = ['Orange', 'MTN', 'Nextell', 'Camtel'];
  telecomPointList: string[] = ['Point Orange Money', 'Point MTN Mobile Money', 'Point Express union', 'Autres'];
  networkQualityList: string[] = ['Très bonne', ' Bonne', 'Moyen', 'Passable', 'Pas bonne', 'Très mauvaise'];
  networkDistributionList: string[] = ['Dans boutiques certifiées', 'Dans boutiques originale (Orange, MTN, Upowa)', 'Par commerciaux', 'Autres'];
  telecomPointOrgIsSelected: boolean;
  telecomPointMtnIsSelected: boolean;
  telecomPointNxtIsSelected: boolean;
  telecomPointOtrIsSelected: boolean;
  otherQuest8: boolean;
  otherQuest9: boolean;
  otherQuest11: boolean;
  otherQuest12: boolean;
  otherQuest22: boolean;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private builder: FormBuilder,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      this.forUpdate = value.infrastructureData !== null;
      if (this.forUpdate) {
        this.pathValueInForm(value.infrastructureData);
      }
    });
  }

  initForm(): void {
    this.infraForm = this.builder.group({
        stateRoute8: [null, Validators.required],
        otherStateRoute8: [null],
        recommendedDriveWay9: [null, Validators.required],
        otherRecommendedDriveWay9: [null],
        transportCoastAverage10: [null, Validators.required],
        packageExpeditionMode11: [null, Validators.required],
        otherPackageExpeditionMode11: [null],
        transportLocalityFrequency12: [null, Validators.required],
        otherTransportLocalityFrequency12: [null],
        adminInfrastructureState13: [null, Validators.required],
        eneo14: [null, Validators.required],
        powerCutPerWeek15: [null, Validators.required],
        powerCutTime16: [null, Validators.required],
        nonElectrifiedArea17: [null, Validators.required],
        townNonElectrified18: [null],
        telecomNetwork19: [null, Validators.required],
        telecomNetWorkQuality20: [null, Validators.required],
        telecomPoint21: [null, Validators.required],
        otherTelecomPoint21: [null],
        distributionNetwork22: [null, Validators.required],
        otherDistributionNetwork22: [null],
        telecomPointInfos23: this.builder.group({
          telecomPointOrg: this.builder.group({
            pointNumber: [null],
            managerPoint: [null],
            phoneNumber: [null],
          }),
          telecomPointMtn: this.builder.group({
            pointNumber: [null],
            managerPoint: [null],
            phoneNumber: [null],
          }),
          telecomPointNxt: this.builder.group({
            pointNumber: [null],
            managerPoint: [null],
            phoneNumber: [null],
          }),
          telecomPointOtr: this.builder.group({
            pointNumber: [null],
            managerPoint: [null],
            phoneNumber: [null],
          }),
        })
      }
    );
    this.spinner.hide();
  }

  private pathValueInForm(value: InfrastructureData): void {
    this.infraForm.patchValue(value);
    this.checkTelecomOption(value.telecomPoint21);
    const telecomPointInfos = {
      telecomPointOrg: value.telecomPointInfos23[0] ? value.telecomPointInfos23[0] : null,
      telecomPointMtn: value.telecomPointInfos23[1] ? value.telecomPointInfos23[1] : null,
      telecomPointNxt: value.telecomPointInfos23[2] ? value.telecomPointInfos23[2] : null,
      telecomPointOtr: value.telecomPointInfos23[3] ? value.telecomPointInfos23[3] : null,
    };
    this.infraForm.patchValue({telecomPointInfos23: telecomPointInfos});
    this.setOtherQuestionValues(value);
    this.spinner.hide();
  }

  private setOtherQuestionValues(infra: InfrastructureData): void {
    this.otherQuest8 = infra.stateRoute8 ? isNotNullOrUndefined(infra.stateRoute8.filter(item =>
      item === this.stateRouteList[(this.stateRouteList.length - 1)])[0]) : false;
    this.otherQuest9 = infra.recommendedDriveWay9 ? isNotNullOrUndefined(infra.recommendedDriveWay9.filter(item =>
      item === this.recommendedDriveList[(this.recommendedDriveList.length - 1)])[0]) : false;
    this.otherQuest11 = infra.packageExpeditionMode11 ? isNotNullOrUndefined(infra.packageExpeditionMode11.filter(item =>
      item === this.packageExpeditionList[(this.packageExpeditionList.length - 1)])[0]) : false;
    this.otherQuest12 = infra.transportLocalityFrequency12 ? isNotNullOrUndefined(
      infra.transportLocalityFrequency12 === this.transportFrequencyList[(this.transportFrequencyList.length - 1)]) : false;
    this.otherQuest22 = infra.distributionNetwork22 ? isNotNullOrUndefined(infra.distributionNetwork22.filter(item =>
      item === this.networkDistributionList[(this.networkDistributionList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.infraForm.value;
    console.log(formValue);
    const infra: InfrastructureData = {
      stateRoute8: formValue.stateRoute8,
      otherStateRoute8: formValue.otherStateRoute8,
      recommendedDriveWay9: formValue.recommendedDriveWay9,
      otherRecommendedDriveWay9: formValue.otherRecommendedDriveWay9,
      transportCoastAverage10: formValue.transportCoastAverage10,
      packageExpeditionMode11: formValue.packageExpeditionMode11,
      otherPackageExpeditionMode11: formValue.otherPackageExpeditionMode11,
      transportLocalityFrequency12: formValue.transportLocalityFrequency12,
      otherTransportLocalityFrequency12: formValue.otherTransportLocalityFrequency12,
      adminInfrastructureState13: formValue.adminInfrastructureState13,
      eneo14: formValue.eneo14,
      powerCutPerWeek15: formValue.powerCutPerWeek15,
      powerCutTime16: formValue.powerCutTime16,
      nonElectrifiedArea17: formValue.nonElectrifiedArea17,
      townNonElectrified18: formValue.townNonElectrified18,
      telecomNetwork19: formValue.telecomNetwork19,
      telecomNetWorkQuality20: formValue.telecomNetWorkQuality20,
      telecomPoint21: formValue.telecomPoint21,
      otherTelecomPoint21: formValue.otherTelecomPoint21,
      distributionNetwork22: formValue.distributionNetwork22,
      otherDistributionNetwork22: formValue.otherDistributionNetwork22,
      telecomPointInfos23: this.getTelecomPointInfosFromFormValue(formValue)
    };

    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, {infrastructureData: infra}).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['zone', 'activity']);
    });
  }

  getTelecomPointInfosFromFormValue(formValue: any): Array<TelecomPointData> {
    const result = new Array<TelecomPointData>();
    const infosOrg = formValue.telecomPointInfos23.telecomPointOrg as TelecomPointData;
    if (infosOrg.pointNumber !== null || infosOrg.phoneNumber !== null || infosOrg.managerPoint !== null) {
      result.push(infosOrg);
    }
    const infosMtn = formValue.telecomPointInfos23.telecomPointMtn as TelecomPointData;
    if (infosMtn.pointNumber !== null || infosMtn.phoneNumber !== null || infosMtn.managerPoint !== null) {
      result.push(infosMtn);
    }
    const infosNxt = formValue.telecomPointInfos23.telecomPointNxt as TelecomPointData;
    if (infosNxt.pointNumber !== null || infosNxt.phoneNumber !== null || infosNxt.managerPoint !== null) {
      result.push(infosNxt);
    }
    const infosOtr = formValue.telecomPointInfos23.telecomPointOtr as TelecomPointData;
    if (infosOtr.pointNumber !== null || infosOtr.phoneNumber !== null || infosOtr.managerPoint !== null) {
      result.push(infosOtr);
    }
    return result;
  }

  onBack(): void {
    this.infraForm.reset();
    this.localService.onBackClicked();
  }

  checkOtherOption(event: MatSelectChange): void {
    console.log(event);
    // this.isOtherState = ;
    this.telecomPointOrgIsSelected = event.value.filter(tel => tel === this.telecomPointList[0])[0] === this.telecomPointList[0];
    this.telecomPointMtnIsSelected = event.value.filter(tel => tel === this.telecomPointList[1])[0] === this.telecomPointList[1];
    this.telecomPointNxtIsSelected = event.value.filter(tel => tel === this.telecomPointList[2])[0] === this.telecomPointList[2];
    this.telecomPointOtrIsSelected = event.value.filter(tel => tel === this.telecomPointList[3])[0] === this.telecomPointList[3];
    console.log(this.telecomPointOrgIsSelected);
    console.log(this.telecomPointMtnIsSelected);
    console.log(this.telecomPointNxtIsSelected);
    console.log(this.telecomPointOtrIsSelected);
  }

  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 8:
        this.otherQuest8 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.stateRouteList[(this.stateRouteList.length - 1)])[0]);
        break;
      case 9:
        this.otherQuest9 = isNotNullOrUndefined(event.value.filter(local =>
          local === this.recommendedDriveList[(this.recommendedDriveList.length - 1)])[0]);
        break;
      case 11:
        this.otherQuest11 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.packageExpeditionList[(this.packageExpeditionList.length - 1)])[0]);
        break;
      case 12:
        this.otherQuest12 = event.value === this.transportFrequencyList[(this.transportFrequencyList.length - 1)];
        break;
      case 22:
        this.otherQuest22 = isNotNullOrUndefined(event.value.filter(local =>
          local === this.networkDistributionList[(this.networkDistributionList.length - 1)])[0]);
        break;
    }

    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 8:
        if (!this.otherQuest8) {
          this.infraForm.setControl('otherStateRoute8', new FormControl(null));
        }
        break;
      case 9:
        if (!this.otherQuest9) {
          this.infraForm.setControl('otherRecommendedDriveWay9', new FormControl(null));
        }
        break;
      case 11:
        if (!this.otherQuest11) {
          this.infraForm.setControl('otherPackageExpeditionMode11', new FormControl(null));
        }
        break;
      case 12:
        if (!this.otherQuest12) {
          this.infraForm.setControl('otherTransportLocalityFrequency12', new FormControl(null));
        }
        break;
      case 22:
        if (!this.otherQuest22) {
          this.infraForm.setControl('otherDistributionNetwork22', new FormControl(null));
        }
        break;
    }
  }

  checkTelecomOption(data: any): void {
    console.log(data);
    this.telecomPointOrgIsSelected = isNotNullOrUndefined(data[0]);
    this.telecomPointMtnIsSelected = isNotNullOrUndefined(data[1]);
    this.telecomPointNxtIsSelected = isNotNullOrUndefined(data[2]);
    this.telecomPointOtrIsSelected = isNotNullOrUndefined(data[3]);
  }
}
