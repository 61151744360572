<div class="container-fluid">
  <h5 class="form-title">Informations ménage</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="prospectForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Région</mat-label>
        <input matInput type="text" formControlName="region">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Département</mat-label>
        <input matInput type="text" formControlName="department">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Arrondissement</mat-label>
        <input matInput type="text" formControlName="district">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Ville</mat-label>
        <input matInput type="text" formControlName="town">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Village</mat-label>
        <input matInput type="text" formControlName="village">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Nom de l'enquêté</mat-label>
        <input matInput type="text" formControlName="investigatedName">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Position de l’enquêté dans la famille</mat-label>
        <input matInput type="text" formControlName="investigatedPosition">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Contact</mat-label>
        <input matInput type="tel" formControlName="contact">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Localisation (à remplir par l'enquêteur)</mat-label>
        <input matInput type="tel" formControlName="localisation">
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="reset"
                (click)="backToList()"
                color="warn"
                class="responsive-item">
          Back To List
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="reset"
                (click)="cancel()"
                color="warn"
                class="responsive-item">
          Cancel
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!prospectForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!prospectForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
