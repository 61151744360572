<div class="container-fluid">
  <h5 class="form-title">Personnes de références</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="refAuthForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>33 - Autorité de référence dans la ville/ le village ?</mat-label>
        <mat-select multiple formControlName="referenceAuthority33" (selectionChange)="checkOtherChoice($event, 33)">
          <mat-select-trigger>
            {{referenceAuth.value ? referenceAuth.value[0] : ''}}
            <span *ngIf="referenceAuth.value?.length > 1" class="example-additional-selection">
              (+{{referenceAuth.value.length - 1}} {{referenceAuth.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let refAuth of referenceAuthList" [value]="refAuth">{{refAuth}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest33">
        <mat-label>33 - Autres Autorité de référence ?</mat-label>
        <input matInput formControlName="otherReferenceAuthority33" type="text">
      </mat-form-field>
      <div class="pb-2">
        <p class="px-3">34 - Pour chaque personne de référence recensé, renseigner les cases suivantes: </p>
        <ng-container class=" mt-2" formArrayName="referenceAuthorityData34">
          <ng-container *ngFor="let refAuth of refAuthData.controls; let i = index">
            <div  fxLayout
                  fxLayoutAlign="space-between"
                  fxLayout.xs="column"
                  fxLayoutGap.xs="1" [formGroup]="refAuth">
              <mat-form-field appearance="outline" class="mr-2">
                <mat-label>Fonction de la référence</mat-label>
                <input matInput
                       formControlName="function"
                       placeholder="Fonction de la référence">
              </mat-form-field>
              <mat-form-field appearance="outline" class="mr-2">
                <mat-label>Noms et prénoms de la référence</mat-label>
                <input matInput
                       formControlName="longName"
                       placeholder="Noms et prénoms de la référence">
              </mat-form-field>
              <mat-form-field appearance="outline" class="mr-2">
                <mat-label>N° Téléphone de la référence</mat-label>
                <input matInput
                       type="number"
                       formControlName="phone"
                       placeholder="N° Téléphone de la référence">
              </mat-form-field>
              <mat-icon class="delete-btn" (click)="deleteRef(i)">
                delete_forever</mat-icon>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="row flex-row justify-content-center mb-2">
        <a mat-mini-fab>
          <mat-icon class="add-course-btn" (click)="addRef(null, null, null)">add</mat-icon>
        </a>
      </div>
      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >
        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Back to previous step"
                matTooltipClass="example-tooltip-red"
                aria-label="Cancel"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!refAuthForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!refAuthForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
