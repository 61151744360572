<div class="container-fluid">
  <h5 class="form-title">1 - Identification du client</h5>
  <div class="question-with-border" >
    <span [innerHTML]="speech"></span>
  </div>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="customerForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Nom, Prénom</mat-label>
        <input matInput formControlName="fullName" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Age</mat-label>
        <input matInput formControlName="age" type="number">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Sexe</mat-label>
        <mat-select formControlName="sexe">
          <mat-option *ngFor="let item of sexeList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Numéro(s) de téléphone</mat-label>
        <input matInput type="text" formControlName="phone">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Village</mat-label>
        <input matInput type="text" formControlName="village">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Arrondissement</mat-label>
        <input matInput type="text" formControlName="district">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Département</mat-label>
        <input matInput type="text" formControlName="department">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Région</mat-label>
        <input matInput type="text" formControlName="region">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Situation matrimoniale</mat-label>
        <mat-select formControlName="matrimonialSituation">
          <mat-option *ngFor="let item of matrimonialSituation" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Nombre d'enfants à charge</mat-label>
        <input matInput formControlName="nbChild" type="number">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" >
        <mat-label>Profession</mat-label>
        <mat-select multiple formControlName="profession" (selectionChange)="checkOtherChoice($event, 11)">
          <mat-select-trigger>
            {{profession.value ? profession.value[0] : ''}}
            <span *ngIf="profession.value?.length > 1" class="example-additional-selection">
          (+{{profession.value.length - 1}} {{profession.value?.length === 2 ? 'autre' : 'autres'}})
        </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of professionList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherProfession">
        <mat-label>Autres profession ?</mat-label>
        <input matInput type="text" formControlName="otherProfession">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Revenu</mat-label>
        <mat-select formControlName="income">
          <mat-option *ngFor="let item of incomeList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!customerForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!customerForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
