<div class="container-fluid">
  <h5 class="form-title">5 - Evaluation du prix</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <div class="question-with-border" >
      <span [innerHTML]="speech5"></span>
    </div>
    <form class="form" [formGroup]="evalForm" (submit)="onSubmit()">
      <div formGroupName="priceEvaluation5">
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Souhaiteriez-vous payer un produit comme ce ventilateur</mat-label>
          <mat-select formControlName="wantSuchProduct1" (selectionChange)="checkOtherChoice($event, 51)">
            <mat-option *ngFor="let item of wantSuchProductList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>b. Si vous deviez payer le produit choisi en plusieurs mensualités, en combien de mois penseriez-vous le payer?</mat-label>
          <mat-select formControlName="howManyMonth2">
            <mat-option *ngFor="let item of howManyMonthList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>c. Quel acompte seriez-vous prêt·e à payer (FCFA) ?</mat-label>
          <mat-select formControlName="deposit3">
            <mat-option *ngFor="let item of depositList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>d. Quelle mensualité minimum seriez-vous prêt·e à payer (FCFA) ?</mat-label>
          <mat-select formControlName="minMonthlyPayment4">
            <mat-option *ngFor="let item of monthlyPaymentList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>d. Quelle mensualité maximum seriez-vous prêt·e à payer (FCFA) ?</mat-label>
          <mat-select formControlName="maxMonthlyPayment5">
            <mat-option *ngFor="let item of monthlyPaymentList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      </div>

      <div formGroupName="cashPayment6">
        <h5 class="form-title">6 - Paiement en cash</h5>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Est-ce que vous seriez prêt·e à payer {{cashPrice1}} FCFA en cash ?</mat-label>
          <mat-select formControlName="priceInCash1" (selectionChange)="checkOtherChoice($event, 61)">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" *ngIf="priceInCash1.value === ouiNonList[1]">
          <mat-label>b. Est-ce que vous seriez prêt·e à payer {{cashPrice2}} FCFA en cash ?</mat-label>
          <mat-select formControlName="priceInCash2">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" *ngIf="priceInCash2.value === ouiNonList[ouiNonList.length-1]">
          <mat-label>c. Si NON, pourquoi?</mat-label>
          <mat-select formControlName="priceRaison3">
            <mat-option *ngFor="let item of priceRaisonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" *ngIf="(priceRaison.value === priceRaisonList[priceRaisonList.length - 1]) || (priceRaison.value === priceRaisonList[priceRaisonList.length - 2])">
          <mat-label>d. Pourquoi trop cher? Ou autres arguments pourquoi pas prêts à payer ?</mat-label>
          <input matInput formControlName="whyTooExpensive4" type="text">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" *ngIf="(priceRaison.value === priceRaisonList[0]) || (priceRaison.value === priceRaisonList[priceRaisonList.length - 1]) || (priceRaison.value === priceRaisonList[priceRaisonList.length - 2])">
          <mat-label>c. Quel prix cash maximum seriez-vous prêts à payer (FCFA) ?</mat-label>
          <mat-select formControlName="maxCashPrice5">
            <mat-option *ngFor="let item of maxCashPriceList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      </div>

      <div>
        <h5 class="form-title">7 - Paiement en cash 50/50</h5>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Est-ce que vous seriez plus intéressé·e à acheter un produit en 2 tranches dans un intervalle de 6 mois, p.ex. {{cash5050}} FCFA ?</mat-label>
          <mat-select formControlName="cashPaymentFifty7">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div formGroupName="kitOffer8">
        <h5 class="form-title">8 - Offre en kit</h5>
        <div class="question-with-border" >
          <span [innerHTML]="speech8"></span>
        </div>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Est-ce que vous seriez intéressé·e par un kit complet avec une batterie plus puissante et des lampes en plus du ventilateur?</mat-label>
          <mat-select formControlName="kitOfferPlusFan1" (selectionChange)="checkOtherChoice($event, 81)">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" >
          <mat-label>b. Combien seriez-vous prêt·e à ajouter comme minimum d’argent (FCFA) pour l'intégration d'un ventilateur au kit évoqué plus haut ?</mat-label>
          <mat-select formControlName="minPriceAddFan2">
            <mat-option *ngFor="let item of priceAddList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" >
          <mat-label>c. Combien seriez-vous prêt·e à ajouter comme maximum d’argent (FCFA) pour l'intégration d'un ventilateur au kit évoqué plus haut ?</mat-label>
          <mat-select formControlName="maxPriceAddFan3">
            <mat-option *ngFor="let item of priceAddList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!evalForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!evalForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>

</div>
