import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {GeneralLocalityData} from '../../../datas/GeneralLocalityData';
import {LocalityData} from '../../../datas/LocalityData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-general-infos',
  templateUrl: './general-infos.component.html',
  styleUrls: ['./general-infos.component.css']
})
export class GeneralInfosComponent implements OnInit {

  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  genInfosForm: FormGroup;
  forUpdate: boolean;
  get principalTalking(): any { return this.genInfosForm.get('talkingPrincipalLanguages6'); }
  get localLanguage(): any {return this.genInfosForm.get('talkingLocalLanguages7'); }
  residentList: string[] = ['1 - 250', '250 - 500', '500 - 1000', '1000 - 1500', '1500 - 2000',
    '2000 - 2500', '2500 - 3000', 'Plus de 3000'];
  principalTalkingList: string[] = ['Français', 'Anglais', 'Arabe', 'Autre'];
  localLanguageList: string[] = ['Ewondo', 'Bulu', 'Kolo', 'haousa', 'Autre'];
  otherPrincipalLanguages: boolean;
  otherLocaleLanguages: boolean;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private builder: FormBuilder,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      this.forUpdate = value.generalLocalityData !== null;
      if (this.forUpdate) {
        this.pathValueInForm(value.generalLocalityData);
      }
    });
  }

  initForm(): void {
    this.genInfosForm = this.builder.group({
        regionName1: [null, Validators.required],
        departmentName2: [null, Validators.required],
        districtName3: [null, Validators.required],
        villageName4: [null, Validators.required],
        residentNumber5: [null, Validators.required],
        talkingPrincipalLanguages6: [null, Validators.required],
        otherTalkingPrincipalLanguages6: [null],
        talkingLocalLanguages7: [null, Validators.required],
        otherTalkingLocalLanguages7: [null]
      }
    );
    this.spinner.hide();
  }

  private pathValueInForm(value: GeneralLocalityData): void {
    this.genInfosForm.patchValue(value);
    this.setOtherQuestionValues(value);
    this.spinner.hide();
  }


  private setOtherQuestionValues(general: GeneralLocalityData): void {
    this.otherPrincipalLanguages = general.talkingPrincipalLanguages6 ?
      isNotNullOrUndefined(general.talkingPrincipalLanguages6.filter(item =>
      item === this.principalTalkingList[(this.principalTalkingList.length - 1)])[0]) : false;
    this.otherLocaleLanguages = general.talkingLocalLanguages7 ?
      isNotNullOrUndefined(general.talkingLocalLanguages7.filter(item =>
      item === this.localLanguageList[(this.localLanguageList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.genInfosForm.value;
    const genInfos: GeneralLocalityData = {
      regionName1: formValue.regionName1,
      departmentName2: formValue.departmentName2,
      districtName3: formValue.districtName3,
      villageName4: formValue.villageName4,
      residentNumber5: formValue.residentNumber5,
      talkingPrincipalLanguages6: formValue.talkingPrincipalLanguages6,
      otherTalkingPrincipalLanguages6: formValue.otherTalkingPrincipalLanguages6,
      talkingLocalLanguages7: formValue.talkingLocalLanguages7,
      otherTalkingLocalLanguages7: formValue.otherTalkingLocalLanguages7
    };
    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, {
      generalLocalityData: genInfos,
      updatedAt: new Date()
    }).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['zone', 'infra']);
    });
  }

  onBack(): void {
    this.genInfosForm.reset();
    this.localService.onBackClicked();
  }

  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 6:
        this.otherPrincipalLanguages = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.principalTalkingList[(this.principalTalkingList.length - 1)])[0]);
        break;
      case 7:
        this.otherLocaleLanguages = isNotNullOrUndefined(event.value.filter(local =>
          local === this.localLanguageList[(this.localLanguageList.length - 1)])[0]);
        break;
    }

    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 6:
        if (!this.otherPrincipalLanguages) {
          this.genInfosForm.setControl('otherTalkingPrincipalLanguages6', new FormControl(null));
        }
        break;
      case 7:
        if (!this.otherLocaleLanguages) {
          this.genInfosForm.setControl('otherTalkingLocalLanguages7', new FormControl(null));
        }
        break;
    }
  }
}
