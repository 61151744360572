import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {ItemData} from './datas/ItemData';
import {DialogComponent} from './dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService} from './services/local-storage.service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {ProspectingData} from './datas/ProspectingData';
import {RemoteCheckService} from './services/remote-check.service';
import {LocalityData} from './datas/LocalityData';
import {VentilatorData} from './datas/VentilatorData';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit , OnDestroy {
  online: boolean;
  lordText = 'ULand Survey...';
  username = localStorage.getItem(ItemData.USER);
  appIsUpdated: boolean;
  public synchroInterval: any;
  dataToSynchronised: boolean;

  constructor(private spinner: NgxSpinnerService, private router: Router,
              public dialog: MatDialog, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) {
    this.appIsUpdated = ItemData.VERSION.toString() === localStorage.getItem(ItemData.UPDATE.toString());
  }

  ngOnInit(): void {
    this.isDataForSynchro();
    this.synchroInterval = setInterval(() => {
      this.isDataForSynchro();
    }, 1500000); // Timeout de 1 heure pour la Synchro 60000ms (1 Minute)
  }

  logout(): void {
  }

  goToResultList(): void {

  }

  madeSync(): void {
    this.spinner.show();
    console.log('Synchronisation beginning....!');
    // Synchro des information de la BD local
    this.synchroniseLocalityData();
    this.synchroniseProspectingData(null);
    this.synchroniseVentilatorData(null);
    setTimeout(() => {
      this.spinner.hide();
      this.isDataForSynchro();
      console.log('Synchronisation ending....!');
    }, 20000);
  }

  goTo(home: string): void {
    this.router.navigate([home]);
  }

  ngOnDestroy(): void {

  }

  private isDataForSynchro(): void {
    console.log('Checking data for synchro....');
    // check if there are non synchronized data on the database
    if (!this.dataToSynchronised) {
      this.localService.getAllData(ItemData.LOCALITY).then((infos: any) => {
        const locality = infos.filter(local => !local.synchronised)[0];
        this.dataToSynchronised = !!isNotNullOrUndefined(locality);
      }).catch(reason => {
        this.dataToSynchronised = false;
        console.log('No synchro data found  in locality IDDB  !!!');
        console.log(reason);
      });
      this.localService.getAllData(ItemData.PROSPECT).then((pros: any) => {
        const prospect = pros.filter(pro => !pro.synchronised)[0];
        this.dataToSynchronised = this.dataToSynchronised ? true : !!isNotNullOrUndefined(prospect);
      }).catch(reason => {
        this.dataToSynchronised = false;
        console.log('No synchro data found  in prospecting IDDB  !!!');
        console.log(reason);
      });
      this.localService.getAllData(ItemData.VENTILATOR).then((ventilator: any) => {
        const prospect = ventilator.filter(ventilo => !ventilo.synchronised)[0];
        this.dataToSynchronised = this.dataToSynchronised ? true : !!isNotNullOrUndefined(prospect);
      }).catch(reason => {
        this.dataToSynchronised = false;
        console.log('No synchro data found in ventilator IDDB !!!');
        console.log(reason);
      });
    }
  }

  updateApp(): void {
    this.spinner.show();
    localStorage.setItem(ItemData.UPDATE.toString(), ItemData.VERSION.toString());
    this.router.navigate(['zone', 'list']).then(value1 => {
      location.reload();
      this.spinner.hide();
    });
  }

  synchroniseLocalityData(): void {
    this.localService.getAllData(ItemData.LOCALITY).then((localities: Array<LocalityData>) => {
      localities.filter(value => !value.synchronised).forEach(local => {
        this.spinner.show();
        console.log('Synchronise locality with code ----->>>', local.code);
        this.remoteService.genericMethodForPostRequestV1('/locality/create', local).then((value1: any) => {
          // Update data oof zone on the indexedDb locally
          this.updateLocalityInIndexedDB(true, local.code);
          this.synchroniseProspectingData(local.code);
          this.synchroniseVentilatorData(local.code);
        });
      });
    });
  }

  synchroniseProspectingData(localityCode: string): void {
    if (isNotNullOrUndefined(localityCode)) {
      this.localService.getAllData(ItemData.PROSPECT).then((prospects: Array<ProspectingData>) => {
        prospects.filter(value => !value.synchronised && value.localityCode === localityCode).forEach(pros =>  {
          this.spinner.show();
          // send to Server and update synchronised variable
          console.log('Synchronise Propecting data with code ----->>>', pros.code);
          this.remoteService.genericMethodForPostRequestV1('/prospecting/create', pros).then((value1: any) => {
            // Update data oof zone on the indexedDb locally
            this.updateProspectingInIndexedDB(true, pros.code);
          });
        });
      });
    } else {
      this.localService.getAllData(ItemData.PROSPECT).then((prospects: Array<ProspectingData>) => {
        prospects.filter(value => !value.synchronised).forEach(pros =>  {
          this.spinner.show();
          // send to Server and update synchronised variable
          console.log('Synchronise Propecting data with code ----->>>', pros.code);
          this.remoteService.genericMethodForPostRequestV1('/prospecting/create', pros).then((value1: any) => {
            // Update data oof zone on the indexedDb locally
            this.updateProspectingInIndexedDB(true, pros.code);
          });
        });
      });
    }
  }

  synchroniseVentilatorData(localityCode: string): void {
    if (isNotNullOrUndefined(localityCode)) {
      this.localService.getAllData(ItemData.VENTILATOR).then((ventilator: Array<VentilatorData>) => {
        ventilator.filter(value => !value.synchronised && value.localityCode === localityCode).forEach(ventilo =>  {
          this.spinner.show();
          // send to Server and update synchronised variable
          console.log('Synchronise Ventilator data with code ----->>>', ventilo.code);
          this.remoteService.genericMethodForPostRequestV1('/ventilator/create', ventilo).then((value1: any) => {
            // Update data of Ventilator on the indexedDb Ventilator
            this.updateVentilatorInIndexedDB(true, ventilo.code);
          });
        });
      });
    } else {
      this.localService.getAllData(ItemData.VENTILATOR).then((ventilator: Array<VentilatorData>) => {
        ventilator.filter(value => !value.synchronised).forEach(ventilo =>  {
          this.spinner.show();
          // send to Server and update synchronised variable
          console.log('Synchronise Ventilator data with code ----->>>', ventilo.code);
          this.remoteService.genericMethodForPostRequestV1('/ventilator/create', ventilo).then((value1: any) => {
            // Update data of ventilator on the indexedDb Ventilator
            this.updateVentilatorInIndexedDB(true, ventilo.code);
          });
        });
      });
    }
  }

  private updateProspectingInIndexedDB(synchro: boolean, prosCode: any): any {
    this.localService.updateDocumentOnCollection(ItemData.PROSPECT, prosCode, {synchronised: synchro});
    this.spinner.hide();
  }

  private updateLocalityInIndexedDB(synchro: boolean, localCode: any): any {
    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, localCode, {synchronised: synchro});
    this.spinner.hide();
  }


  private updateVentilatorInIndexedDB(synchro: boolean, ventilatorCode: any): any {
    this.localService.updateDocumentOnCollection(ItemData.VENTILATOR, ventilatorCode, {synchronised: synchro});
    this.spinner.hide();
  }



  addNewZone(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: {
        response: true,
        title: 'Ajouter une nouvelle zone',
        question: '<p>Ajouter une nouvelle zone ? <br/><br/><span style="color: darkred; font-style: italic">Cette action changera la zone actuelle dans le système.</span></p>'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        localStorage.removeItem(ItemData.CURRENT_ZONE);
        this.router.navigate(['zone', 'new']);
      }
    });
  }
}
