import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemData} from '../../../datas/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {ProspectingData} from '../../../datas/ProspectingData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {CensusConcurrentData} from '../../../datas/CensusConcurrentData';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-concurrent-form',
  templateUrl: './concurrent-form.component.html',
  styleUrls: ['./concurrent-form.component.css']
})
export class ConcurrentFormComponent implements OnInit {

  concurrentForm: FormGroup;
  currentProspectCode = localStorage.getItem(ItemData.CURRENT_PROSPECT);

  forUpdate: boolean;

  get deviceType(): any {return this.concurrentForm.get('deviceType2'); }
  get buyYourself(): any {return this.concurrentForm.get('buyYourself4'); }
  get deviceSatisfaction(): any {return this.concurrentForm.get('deviceSatisfaction8'); }
  get whyUnsatisfied(): any {return this.concurrentForm.get('whyUnsatisfied9'); }
  get paymentTypeUsed(): any {return this.concurrentForm.get('paymentTypeUsed10'); }
  get paymentModeUsed(): any {return this.concurrentForm.get('paymentModeUsed13'); }
  get provideSAV(): any {return this.concurrentForm.get('provideSAV14'); }

  ouiNonList: string[] = ['Oui', 'Non'];
  deviceBrandList: string[] = ['UpOwa', 'Mpower', 'Oolu', 'Africa global Energy', 'Orange', 'Solaring',
    'D. Light', 'Canopy', 'Solarus', 'Solightech', 'Solar cameroon', 'Instrumelec Cameroun', 'Aksis Energy', 'Cameroon solar solution SARL', 'Hias corporation', 'African solar generation', 'Autres'];
  deviceTypeList: string[] = ['Lampes (préciser le nombre)', 'Torches', 'Chargeur de téléphon', 'TV', 'Radio', 'Autres'];
  boughtByWhomList: string[] = ['Don', 'Membre de la famille', 'Autres'];
  buyCostList: string[] = ['Moins de 50 000 FCFA', '51 à 100 000 FCFA', '100 à 200 000 FCFA', 'Plus de 200 000 FCFA', 'Paiement en tranche tous les mois'];
  buyingPlaceList: string[] = ['Passage d\'un commercial sur place', 'Dans une boutique', 'Autres'];
  whyUnsatisfiedList: string[] = ['Pas de bonne qualité', 'Ils se déchargent très rapidement', 'Panne régulière des appareils', 'Les pris sont élevés', 'Autres'];
  paymentTypeUsedList: string[] = ['Paiement au cash', 'Paiement à crédit'];
  monthlyPaymentList: string[] = ['06 mois', '12 mois', '18 mois', 'Plus de 24 mois'];
  paymentModeUsedList: string[] = ['Orange money', 'MTN Mobile Money', 'Express union', 'Autres'];
  serviceList: string[] = ['Service client', 'Un contact téléphonique', 'Autres'];
  lampNumberList: string[] = ['01', '02', '03', '04', '05', '06', 'Plus de 06'];
  firstPaymentList: string[] = ['5000 FCFA à 10 000 FCFA', '10 000 FCFA à 20 000 FCFA', '21 000 FCFA à 30 000 FCFA',
    '31 000 FCFA à 40 000 FCFA', '41 000 FCFA à 50 000 FCFA', '50 000 FCFA et plus'];

  otherQuest1: boolean;
  otherQuest2: boolean;
  otherQuest5: boolean;
  otherQuest7: boolean;
  otherQuest9: boolean;
  otherQuest13: boolean;
  otherQuest15: boolean;
  lampNumber3: boolean;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    if (this.currentProspectCode) {
      this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((value: ProspectingData) => {
        console.log(value);
        if (isNotNullOrUndefined(value.form3)) {
          this.forUpdate = value.form3 !== null;
          this.pathValueInForm(value.form3);
        }
      });
    } else {
      this.router.navigate(['prospect', 'new']);
    }
  }

  initForm(): void {
    this.concurrentForm = this.formBuilder.group({
      deviceBrand1: [null, Validators.required],
      otherDeviceBrand1: [null],
      deviceType2: [null, Validators.required],
      otherDeviceType2: [null],
      lampNumber3: [null],
      buyYourself4: [null, Validators.required],
      boughtByWhom5: [null],
      otherBoughtByWhom5: [null],
      buyCost6: [null, Validators.required],
      buyingPlace7: [null, Validators.required],
      otherBuyingPlace7: [null],
      deviceSatisfaction8: [null, Validators.required],
      whyUnsatisfied9: [null],
      otherWhyUnsatisfied9: [null],
      paymentTypeUsed10: [null, Validators.required],
      monthlyPayment11: [null],
      firstPaymentAmount12: [null, Validators.required],
      paymentModeUsed13: [null, Validators.required],
      otherPaymentModeUsed13: [null],
      provideSAV14: [null, Validators.required],
      service15: [null],
      otherService15: [null]
    });
    this.spinner.hide();
  }

  private pathValueInForm(form3: CensusConcurrentData): void {
    this.concurrentForm.patchValue(form3);
    this.setOtherQuestionValues(form3);
    this.spinner.hide();
  }


  private setOtherQuestionValues(form3: CensusConcurrentData): void {
    this.otherQuest1 = form3.deviceBrand1 ? form3.deviceBrand1 === this.deviceBrandList[(this.deviceBrandList.length - 1)] : false;
    this.otherQuest2 = form3.deviceType2 ? isNotNullOrUndefined(form3.deviceType2.filter(item =>
      item === this.deviceTypeList[(this.deviceTypeList.length - 1)])[0]) : false;
    this.lampNumber3 = form3.deviceType2 ? isNotNullOrUndefined(form3.deviceType2.filter(item =>
      item === this.deviceTypeList[0])[0]) : false;
    this.otherQuest5 = form3.boughtByWhom5 ? form3.boughtByWhom5 === this.boughtByWhomList[(this.boughtByWhomList.length - 1)] : false;
    this.otherQuest7 = form3.buyingPlace7 ? form3.buyingPlace7 === this.buyingPlaceList[(this.buyingPlaceList.length - 1)] : false;
    this.otherQuest9 = form3.whyUnsatisfied9 ? isNotNullOrUndefined(form3.whyUnsatisfied9.filter(item =>
      item === this.whyUnsatisfiedList[(this.whyUnsatisfiedList.length - 1)])[0]) : false;
    this.otherQuest13 = form3.paymentModeUsed13 ? isNotNullOrUndefined(form3.paymentModeUsed13.filter(item =>
      item === this.paymentModeUsedList[(this.paymentModeUsedList.length - 1)])[0]) : false;
    this.otherQuest15 = form3.service15 ? form3.service15 === this.serviceList[(this.serviceList.length - 1)] : false;
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.concurrentForm.value;
    console.log(formValue);
    const concurrentData: CensusConcurrentData = {
      deviceBrand1: formValue.deviceBrand1,
      otherDeviceBrand1: formValue.otherDeviceBrand1,
      deviceType2: formValue.deviceType2,
      otherDeviceType2: formValue.otherDeviceType2,
      lampNumber3: formValue.lampNumber3,
      buyYourself4: formValue.buyYourself4,
      boughtByWhom5: formValue.boughtByWhom5,
      otherBoughtByWhom5: formValue.otherBoughtByWhom5,
      buyCost6: formValue.buyCost6,
      buyingPlace7: formValue.buyingPlace7,
      otherBuyingPlace7: formValue.otherBuyingPlace7,
      deviceSatisfaction8: formValue.deviceSatisfaction8,
      whyUnsatisfied9: formValue.whyUnsatisfied9,
      otherWhyUnsatisfied9: formValue.otherWhyUnsatisfied9,
      paymentTypeUsed10: formValue.paymentTypeUsed10,
      monthlyPayment11: formValue.monthlyPayment11,
      firstPaymentAmount12: formValue.firstPaymentAmount12,
      paymentModeUsed13: formValue.paymentModeUsed13,
      otherPaymentModeUsed13: formValue.otherPaymentModeUsed13,
      provideSAV14: formValue.provideSAV14,
      service15: formValue.service15,
      otherService15: formValue.otherService15
    };

    const updateDate = {
      form1: null,
      updatedAt: new Date(),
      form2: null,
      form3: concurrentData,
      synchronised: null
    };

    this.localService.getOnDocumentWithCode(ItemData.PROSPECT, this.currentProspectCode).then((pros: ProspectingData) => {
      pros.form1 = updateDate.form1;
      pros.form2 = updateDate.form2;
      pros.form3 = updateDate.form3;
      pros.updatedAt = updateDate.updatedAt;
      // call update backend endpoint for the request
      this.remoteService.genericMethodForPostRequestV1('/prospecting/create', pros).then((value1: any) => {
        // Update data of Prospecting on the indexedDb Prospecting
        this.updateProspectingInIndexedDB(true, updateDate);
      }).catch(reason => {
        console.log(reason);
        this.updateProspectingInIndexedDB(false, updateDate);
      });
    });
  }

  private updateProspectingInIndexedDB(synchro: boolean, data: any): any {
    data.synchronised = synchro;
    this.localService.updateDocumentOnCollection(ItemData.PROSPECT, this.currentProspectCode, data).then(updatedData => {
      console.log(updatedData);
      this.spinner.hide();
      localStorage.removeItem(ItemData.CURRENT_PROSPECT);
      this.router.navigate(['zone', 'detail']);
    });
  }


  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 1:
        this.otherQuest1 = event.value === this.deviceBrandList[(this.deviceBrandList.length - 1)];
        break;
      case 2:
        this.otherQuest2 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.deviceTypeList[(this.deviceTypeList.length - 1)])[0]);
        this.lampNumber3 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.deviceTypeList[0])[0]);
        break;
      case 5:
        this.otherQuest5 = event.value === this.boughtByWhomList[(this.boughtByWhomList.length - 1)];
        break;
      case 7:
        this.otherQuest7 = event.value === this.buyingPlaceList[(this.buyingPlaceList.length - 1)];
        break;
      case 9:
        this.otherQuest9 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.whyUnsatisfiedList[(this.whyUnsatisfiedList.length - 1)])[0]);
        break;
      case 13:
        this.otherQuest13 = isNotNullOrUndefined(event.value.filter(item =>
          item === this.paymentModeUsedList[(this.paymentModeUsedList.length - 1)])[0]);
        break;
      case 15:
        this.otherQuest15 = event.value === this.serviceList[(this.serviceList.length - 1)];
        break;
    }
    this.restFormControlsIfOtherUnSelected(question);
  }

  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 1:
        if (!this.otherQuest1) {
         this.concurrentForm.setControl('otherDeviceBrand1', new FormControl(null));
        }
        break;
      case 2:
        if (!this.otherQuest2) {
          this.concurrentForm.setControl('otherDeviceType2', new FormControl(null));
          if (!this.lampNumber3) {
            this.concurrentForm.setControl('lampNumber3', new FormControl(null));
          }
        }
        break;
      case 5:
        if (!this.otherQuest5) {
          this.concurrentForm.setControl('otherBoughtByWhom5', new FormControl(null));
        }
        break;
      case 7:
        if (!this.otherQuest7) {
          this.concurrentForm.setControl('otherBuyingPlace7', new FormControl(null));
        }
        break;
      case 9:
        if (!this.otherQuest9) {
          this.concurrentForm.setControl('otherWhyUnsatisfied9', new FormControl(null));
        }
        break;
      case 13:
        if (!this.otherQuest13) {
          this.concurrentForm.setControl('otherPaymentModeUsed13', new FormControl(null));
        }
        break;
      case 15:
        if (!this.otherQuest15) {
          this.concurrentForm.setControl('otherService15', new FormControl(null));
        }
        break;
    }
  }

  onBack(): void {
    this.concurrentForm.reset();
    this.localService.onBackClicked();
  }
}
