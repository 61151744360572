export const environment = {
  production: true,
  backendUrl: 'https://test-back.usurvey.upowa.org',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCBkakKq2Go3M-pwNalN4lfMhORx-okSDs',
    authDomain: 'uland-survey.firebaseapp.com',
    projectId: 'uland-survey',
    storageBucket: 'uland-survey.appspot.com',
    messagingSenderId: '800573664852',
    appId: '1:800573664852:web:64de6974edd7d04e9687c1',
    measurementId: 'G-P2W6KZFLBJ'
  }
};
