<div class="container-fluid" >
  <h5 class="form-title" style="cursor: pointer" (click)="goToLocalityList()">Infos de prospection sur la localité: {{localityName}}</h5>
  <mat-grid-list cols="5" rowHeight="100px" *ngFor="let postect of prospectingData">
    <mat-grid-tile colspan="4">
      <mat-card style="width: 98%; padding: 13px; height: 50px" fxHide.xs="true">
          <span> <strong>Village: </strong> {{postect.identificationMenage.village}}</span> <span class="mx-2">|</span>
          <span><strong>Enquêté: </strong> {{postect.identificationMenage.investigatedName}}</span>
      </mat-card>
      <mat-card style="width: 98%; padding: 13px;" fxLayout fxLayout.xs="column" fxLayoutGap="5"  fxHide.md="true" fxHide.sm="true" fxHide.lg="true" fxHide.xl="true">
        <span> <strong>Village: </strong> {{postect.identificationMenage.village}}</span>
        <span><strong>Enquêté: </strong> {{postect.identificationMenage.investigatedName}}</span>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-icon-button (click)="onEditProspecting(postect)">
        <mat-icon color="warn"> edit</mat-icon>
      </button>
      <button mat-icon-button>
        <mat-icon *ngIf="postect.synchronised" class="green-color"> sync</mat-icon>
        <mat-icon *ngIf="!postect.synchronised" class="red-color"> sync_disabled</mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="mgs-top" *ngIf="prospectingData.length <= 0">
    <img class="responsive-item img-padding" src="assets/images/nodata.png"/>
  </div>
  <section class="add-button">
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-fab aria-label="add answer icon" class="upowa-color-primary" (click)="onNewProspecting()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
