import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalityData} from '../../../datas/LocalityData';
import {PrincipalActivityData} from '../../../datas/PrincipalActivityData';
import {MatSelectChange} from '@angular/material/select';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  activityForm: FormGroup;
  forUpdate: boolean;

  get principalActivity(): any { return this.activityForm.get('principalActivity24'); }
  get secondaryActivity(): any { return this.activityForm.get('secondaryActivity25'); }
  get culture1(): any { return this.activityForm.get('harvestPeriod26.culture1'); }
  get culture2(): any { return this.activityForm.get('harvestPeriod26.culture2'); }
  get culture3(): any { return this.activityForm.get('harvestPeriod26.culture3'); }
  get other(): any { return this.activityForm.get('harvestPeriod26.other'); }
  get foundInLocality(): any { return this.activityForm.get('foundInLocality27'); }

  principalActivityList: string[] = ['Agriculture - type: maïs, arachide , coton , soja, oignon, riz, sorgho', 'Élevage -  type: mouton, chèvre, bœuf', 'Commerce - type : boissons, boivines, poissons, pièces détachées, friperies', 'Fonctionnaire', 'Indépendant',
    'Artisanat (ouvrage, plomberie, menuiserie, forge, garage, soudure, etc.)', 'Horeca (Hotel-Restaurant-Café)', 'Profession libérale (Avocat-e, Huissier, Opticien-ne, Vétérinaire, Artiste, Journaliste, Médecin, Diététicien-ne, Architecte, Psychologue, Expert-e comptable, etc.)', 'Retraité-e', 'Autres'];
  culturePeriodList: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  foundInLocalityList: string[] = ['Boutique d’alimentation générale', 'Mairie', 'Centre de santé/ hôpital', 'Épicerie',
    'Boutique vente des pièces auto', 'Multiservices', 'Salon de coiffure', 'Boulangerie', 'Groupements professionnelles',
    'Ecole', 'Associations', 'Pharmacie', 'Bar', 'Café/Restaurant', 'Boutique éléctronique', 'Eglise', 'Hôtel/Motel/Auberge',
    'Revendeur canal+', 'Instituts et bureaux de microfinance', 'Autres'];
  numberOfList: string[] = ['1', '2', '3', '4', '+ 4'];
  numberOfTypeList: any[] = [{name: 'boutiqueG', text: 'boutique d’alimentation générale'}, {name: 'centreCente', text: 'Centre de santé/ hôpital'},
    {name: 'boutiqueAuto', text: 'Boutique vente des pièces auto'}, {name: 'multiservices', text: 'Multiservices'},
    {name: 'salonCoif', text: 'Salon de coiffure'}, {name: 'boulangerie', text: 'Boulangerie'},
    {name: 'ecole', text: 'Ecole'}, {name: 'groupPro', text: 'Groupements professionnelles'},
    {name: 'bar', text: 'Bar'}, {name: 'hma', text: 'Hôtel/Motel/Auberge'},
    {name: 'cafeResto', text: 'Café/Restaurant'}, {name: 'institutBeaute', text: 'Instituts et bureaux de microfinance'},
    {name: 'autre', text: 'Autre'}
  ];
  percentOfChildList: string[] = ['0-10%', '10-20%', '20-30%', '30-40%', '40-50%',
    '50-60%', '60-70%', '70-80%', '80-90%', '90-100%'];
  otherQuest24: boolean;
  otherQuest25: boolean;
  otherQuest27: boolean;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private builder: FormBuilder,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      this.forUpdate = value.principalActivity !== null;
      if (this.forUpdate) {
        this.pathValueInForm(value.principalActivity);
      }
    });
  }

  initForm(): void {
    this.activityForm = this.builder.group({
      principalActivity24: [null, Validators.required],
      otherPrincipalActivity24: [null],
      secondaryActivity25: [null, Validators.required],
      otherSecondaryActivity25: [null],
      harvestPeriod26: this.builder.group({
        culture1: [null],
        culture2: [null],
        culture3: [null],
        other: [null]
      }),
      foundInLocality27: [null, Validators.required],
      otherFoundInLocality27: [null],
      numberByType28: this.toFormGroup(),
      scholarshipChildInLocality29: [null, Validators.required]
    });
    this.spinner.hide();
  }

  toFormGroup(): FormGroup {
    const group: any = {};

    this.numberOfTypeList.forEach(typeOf => {
      group[typeOf.name] = new FormControl(null);
    });
    return new FormGroup(group);
  }

  private pathValueInForm(data: PrincipalActivityData): void {
    this.activityForm.patchValue(data);
    this.setOtherQuestionValues(data);
  }


  private setOtherQuestionValues(activity: PrincipalActivityData): void {
    this.otherQuest24 = activity.principalActivity24 ? isNotNullOrUndefined(activity.principalActivity24.filter(item =>
      item === this.principalActivityList[(this.principalActivityList.length - 1)])[0]) : false;
    this.otherQuest25 = activity.secondaryActivity25 ? isNotNullOrUndefined(activity.secondaryActivity25.filter(item =>
      item === this.principalActivityList[(this.principalActivityList.length - 1)])[0]) : false;
    this.otherQuest27 = activity.foundInLocality27 ? isNotNullOrUndefined(activity.foundInLocality27.filter(item =>
      item === this.foundInLocalityList[(this.foundInLocalityList.length - 1)])[0]) : false;
  }


  onSubmit(): void {
    this.spinner.show();
    const formValue = this.activityForm.value;
    console.log(formValue);
    const activity: PrincipalActivityData = {
      principalActivity24: formValue.principalActivity24,
      otherPrincipalActivity24: formValue.otherPrincipalActivity24,
      secondaryActivity25: formValue.secondaryActivity25,
      otherSecondaryActivity25: formValue.otherSecondaryActivity25,
      harvestPeriod26: formValue.harvestPeriod26,
      foundInLocality27: formValue.foundInLocality27,
      otherFoundInLocality27: formValue.otherFoundInLocality27,
      numberByType28: formValue.numberByType28,
      scholarshipChildInLocality29: formValue.scholarshipChildInLocality29
    };
    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, {principalActivity: activity}).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['zone', 'event']);
    });
  }


  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 24:
        this.otherQuest24 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.principalActivityList[(this.principalActivityList.length - 1)])[0]);
        break;
      case 25:
        this.otherQuest25 = isNotNullOrUndefined(event.value.filter(local =>
          local === this.principalActivityList[(this.principalActivityList.length - 1)])[0]);
        break;
      case 27:
        this.otherQuest27 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.foundInLocalityList[(this.foundInLocalityList.length - 1)])[0]);
        break;
    }

    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 24:
        if (!this.otherQuest24) {
          this.activityForm.setControl('otherPrincipalActivity24', new FormControl(null));
        }
        break;
      case 25:
        if (!this.otherQuest25) {
          this.activityForm.setControl('otherSecondaryActivity25', new FormControl(null));
        }
        break;
      case 27:
        if (!this.otherQuest27) {
          this.activityForm.setControl('otherFoundInLocality27', new FormControl(null));
        }
        break;
    }
  }

  onBack(): void {
    this.activityForm.reset();
    this.localService.onBackClicked();
  }
}
