import {Injectable} from '@angular/core';
import Localbase from 'localbase';
import {Location} from '@angular/common';

const db = new Localbase('uLandSurvey_db');

function getLocalStorage(): Storage {
  return localStorage;
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private $localStorage: Storage;

  constructor(private location: Location) {
    this.$localStorage = this.localStorage;
  }

  get localStorage(): Storage {
    return getLocalStorage();
  }


  onBackClicked(): void {
    this.location.back();
  }

  onFrowardClicked(): void {
    this.location.forward();
  }

  setInfo(item: string, data: any): void {
    const jsonData = JSON.stringify(data);
    this.$localStorage.setItem(item, jsonData);
  }

  loadInfo(item: string): any {
    return JSON.parse(this.$localStorage.getItem(item));
  }

  clearInfo(item: string): void {
    this.$localStorage.removeItem(item);
  }

  clearAllLocalStorage(): void {
    this.$localStorage.clear();
  }

  getAllData(collection: string): Promise<any> {
      return db.collection(collection).get();
  }

  addDocumentOnCollection(collection: string, data: any): Promise<any> {
    return db.collection(collection).add(data);
  }

  addDocumentOnCollectionWithKey(collection: string, data: any, key: string): Promise<any> {
    return db.collection(collection).add(data, key);
  }

  getOneDocumentWithKey(collection: string, key: string): Promise<any> {
    return db.collection(collection).doc(key).get();
  }

  getOnDocumentWithCode(collection: string, codeKey: string): Promise<any> {
    return db.collection(collection).doc({code: codeKey}).get();
  }

  getOnDocumentWithSynchro(collection: string, synchro: boolean): Promise<any> {
    return db.collection(collection).doc({synchronised: synchro}).get();
  }

  getOnDocumentQuestionWithOrdre(collection: string, ordreKey: number): Promise<any> {
    return db.collection(collection).doc({ordre: ordreKey}).get();
  }

  deleteDocumentOnCollection(collection: string, key: any): Promise<any> {
    return db.collection(collection).doc({code: key}).delete();
  }

  deleteCollection(collection: string): Promise<any> {
    return db.collection(collection).delete();
  }

  updateDocumentOnCollection(collection: string, key: string, updateData: any): Promise<any> {
    return db.collection(collection).doc({code: key}).update(updateData);
  }

  setCollection(collection: string, data: Array<any>): Promise<any> {
    return db.collection(collection).set(data);
  }
}
