import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogComponent} from './dialog/dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import {LocalStorageService} from './services/local-storage.service';
import {CachingInterceptor} from './services/cache/caching-interceptor.service';
import {RemoteCheckService} from './services/remote-check.service';
import {ZoneFormComponent} from './components/zone-form/zone-form.component';
import {GeneralInfosComponent} from './components/zone-form/general-infos/general-infos.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {InfrastructureComponent} from './components/zone-form/infrastructure/infrastructure.component';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {ActivityComponent} from './components/zone-form/activity/activity.component';
import {EventsComponent} from './components/zone-form/events/events.component';
import {CommentsComponent} from './components/zone-form/comments/comments.component';
import {ZoneListComponent} from './components/zone-form/zone-list/zone-list.component';
import {ReferencesComponent} from './components/zone-form/references/references.component';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {ZoneDetailsComponent} from './components/zone-details/zone-details.component';
import {ProspectionFormComponent} from './components/prospection-form/prospection-form.component';
import {ChoiceFormComponent} from './components/prospection-form/choice-form/choice-form.component';
import {ClientFormComponent} from './components/prospection-form/client-form/client-form.component';
import {GeneralFormComponent} from './components/prospection-form/general-form/general-form.component';
import {ConcurrentFormComponent} from './components/prospection-form/concurrent-form/concurrent-form.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {QuestionListComponent} from './components/question-list/question-list.component';
import {VantilatorDetailsComponent} from './components/vantilator/vantilator-details/vantilator-details.component';
import {VentilatorFormComponent} from './components/vantilator/ventilator-form/ventilator-form.component';
import {VentilatorCustomerComponent} from './components/vantilator/ventilator-customer/ventilator-customer.component';
import {VentilatorVentiloComponent} from './components/vantilator/ventilator-ventilo/ventilator-ventilo.component';
import {VentilatorEvalComponent} from './components/vantilator/ventilator-eval/ventilator-eval.component';
import {VentilatorZoneComponent} from './components/vantilator/ventilator-zone/ventilator-zone.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AppUpdateService} from './services/app-update.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SpinnerComponent,
    DialogComponent,
    ZoneFormComponent,
    GeneralInfosComponent,
    InfrastructureComponent,
    ActivityComponent,
    EventsComponent,
    CommentsComponent,
    ZoneListComponent,
    ReferencesComponent,
    ZoneDetailsComponent,
    ProspectionFormComponent,
    ChoiceFormComponent,
    ClientFormComponent,
    GeneralFormComponent,
    ConcurrentFormComponent,
    QuestionListComponent,
    VantilatorDetailsComponent,
    VentilatorFormComponent,
    VentilatorCustomerComponent,
    VentilatorVentiloComponent,
    VentilatorEvalComponent,
    VentilatorZoneComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
        NgxSpinnerModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatExpansionModule,
        MatListModule,
        MatGridListModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MatButtonToggleModule
    ],
  providers: [
    AuthService,
    AuthGuardService,
    LocalStorageService,
    RemoteCheckService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    AppUpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
