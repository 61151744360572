import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalityData} from '../../../datas/LocalityData';
import {EventLocalityData} from '../../../datas/EventLocalityData';
import {MatSelectChange} from '@angular/material/select';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  eventForm: FormGroup;
  forUpdate: boolean;

  get isEvent(): any { return this.eventForm.get('isEventInLocality30'); }
  get eventInLocality(): any { return this.eventForm.get('event31'); }
  get eventPeriod(): any { return this.eventForm.get('eventPeriod32'); }

  isEventList: string[] = ['Oui', 'Non'];
  eventsList: string[] = ['Festival', 'Foire', 'Fête culturelle', 'Jour de marché', 'Réunion association de femmes (ex. tontines) : type(s)……fête de récolte…….', 'Autres (préciser)'];
  eventsInLocality: any[] = [{name: 'festival', text: 'Festival'}, {name: 'foire', text: 'Foire'},
    {name: 'feteCulturelle', text: 'Fête Culturelle'}, {name: 'jourMarche', text: 'Jour de marché'},
    {name: 'autre', text: 'Autre'}
  ];
  periodList: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  otherQuest31: boolean;

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private builder: FormBuilder,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((value: LocalityData) => {
      this.forUpdate = value.eventLocality !== null;
      if (this.forUpdate) {
        this.pathValueInForm(value.eventLocality);
      }
    });
  }

  initForm(): void {
    this.eventForm = this.builder.group({
      isEventInLocality30: [null, Validators.required],
      event31: [null, Validators.required],
      otherEvent31: [null],
      eventPeriod32: this.toFormGroup()
    });
    this.spinner.hide();
  }

  toFormGroup(): FormGroup {
    const group: any = {};

    this.eventsInLocality.forEach(event => {
      group[event.name] = new FormControl(null);
    });
    return new FormGroup(group);
  }

  private pathValueInForm(data: any): void {
    this.eventForm.patchValue(data);
    this.setOtherQuestionValues(data);
  }


  private setOtherQuestionValues(event: EventLocalityData): void {
    this.otherQuest31 = event.event31 ?
      isNotNullOrUndefined(event.event31.filter(item =>
        item === this.eventsList[(this.eventsList.length - 1)])[0]) : false;
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.eventForm.value;
    console.log(formValue);
    const event: EventLocalityData = {
      isEventInLocality30: formValue.isEventInLocality30,
      event31: formValue.event31,
      otherEvent31: formValue.otherEvent31,
      eventPeriod32: formValue.eventPeriod32
    };
    this.localService.updateDocumentOnCollection(ItemData.LOCALITY, this.currentZoneCode, {eventLocality: event}).then(value => {
      console.log(value);
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['zone', 'reference']);
    });
  }


  checkOtherChoice(event: MatSelectChange, question: number): void {
    switch (question) {
      case 31:
        this.otherQuest31 = isNotNullOrUndefined(event.value.filter(principal =>
          principal === this.eventsList[(this.eventsList.length - 1)])[0]);
        break;
    }

    this.restFormControlsIfOtherUnSelected(question);
  }


  restFormControlsIfOtherUnSelected(question: number): void {
    switch (question) {
      case 31:
        if (!this.otherQuest31) {
          this.eventForm.setControl('otherEvent31', new FormControl(null));
        }
        break;
    }
  }

  onBack(): void {
    this.eventForm.reset();
    this.localService.onBackClicked();
  }

}
