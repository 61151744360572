<div class="container-fluid">
  <h5 class="form-title">2 - Ventilateur</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="ventiloForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>a. Avez-vous déjà un ventilateur ?</mat-label>
        <mat-select formControlName="ventilator2" (selectionChange)="checkOtherChoice($event, 1)">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <hr class="mx-auto" style="width: 80%; margin-bottom: 30px !important;">
      <div *ngIf="ventilator.value === ouiNonList[0]" formGroupName="ventilatorUsage3">
        <h5 class="form-title">3 - Usage ventilateur</h5>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Depuis combien de temps avez-vous déjà ce ventilateur ?</mat-label>
          <mat-select formControlName="howManyTime1">
            <mat-option *ngFor="let item of howManyTimeList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" >
          <mat-label>b. A quel moment de la journée l’utilisez-vous ?</mat-label>
          <mat-select multiple formControlName="whatTimeUseIt2">
            <mat-select-trigger>
              {{whatTimeUseIt.value ? whatTimeUseIt.value[0] : ''}}
              <span *ngIf="whatTimeUseIt.value?.length > 1" class="example-additional-selection">
                (+{{whatTimeUseIt.value.length - 1}} {{whatTimeUseIt.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let item of whatTimeUseItList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>c. Par quelle source d’énergie votre ventilateur est-il alimenté principalement ?</mat-label>
          <mat-select formControlName="powerByWhatEnergy3">
            <mat-option *ngFor="let item of powerByWhatEnergyList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>d. Avez-vous des problèmes avec le produit actuel ?</mat-label>
          <mat-select formControlName="haveProblem4">
            <mat-option *ngFor="let item of haveProblemList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>e. Si vous deviez changer quelque chose actuellement sur votre ventilateur qu'est ce que ce serait ?</mat-label>
          <input matInput formControlName="whatToChange5" type="text">
        </mat-form-field>
      </div>
      <div *ngIf="ventilator.value === ouiNonList[1] || ventilator.value === ouiNonList[0]" formGroupName="ventilatorProposition4">
        <h5 class="form-title">4 - Proposition du ventilateur upOwa</h5>
        <div class="question-with-border" >
          <span [innerHTML]="speech"></span>
        </div>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>a. Seriez-vous prêt·e à vous équiper d'un ventilateur fonctionnant au solaire dans votre foyer/ménage ?</mat-label>
          <mat-select formControlName="readyForSolarFan1">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>b. Le client est-il intéressé·e par le ventilateur proposé ?</mat-label>
          <mat-select formControlName="customerIsInterest2">
            <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" >
          <mat-label>c. Dans quel cadre sera-t-il utilisé ?</mat-label>
          <mat-select multiple formControlName="caseOfUse3" (selectionChange)="checkOtherChoice($event, 4.3)">
            <mat-select-trigger>
              {{caseOfUse3.value ? caseOfUse3.value[0] : ''}}
              <span *ngIf="caseOfUse3.value?.length > 1" class="example-additional-selection">
                (+{{caseOfUse3.value.length - 1}} {{caseOfUse3.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let item of casOfUseList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest3">
          <mat-label>d. Dans quel autre cadre professionnel sera-t-il utilisé ?</mat-label>
          <input matInput formControlName="otherCaseOfUse3" type="text">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" >
          <mat-label>e. Pour quelles raisons utiliseriez-vous un ventilateur? Plusieurs réponses possibles ?</mat-label>
          <mat-select multiple formControlName="raisonOfUseFan4" (selectionChange)="checkOtherChoice($event, 4.4)">
            <mat-select-trigger>
              {{raisonOfUseFan.value ? raisonOfUseFan.value[0] : ''}}
              <span *ngIf="raisonOfUseFan.value?.length > 1" class="example-additional-selection">
                (+{{raisonOfUseFan.value.length - 1}} {{raisonOfUseFan.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let item of reasonOfUseFanList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest4">
          <mat-label>f. Pour quelle autre raison l’utiliseriez-vous ?</mat-label>
          <input matInput formControlName="otherRaisonOfUseFan4" type="text">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element" >
          <mat-label>g. A quel moment de la journée l'utiliseriez-vous ?</mat-label>
          <mat-select multiple formControlName="whatTimeUseIt5">
            <mat-select-trigger>
              {{whatTimeUseItElse.value ? whatTimeUseItElse.value[0] : ''}}
              <span *ngIf="whatTimeUseItElse.value?.length > 1" class="example-additional-selection">
                (+{{whatTimeUseItElse.value.length - 1}} {{whatTimeUseItElse.value?.length === 2 ? 'autre' : 'autres'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let item of whatTimeUseItList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!ventiloForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!ventiloForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>

</div>
