import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../../datas/ItemData';
import {VentilatorData} from '../../../datas/VentilatorData';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalityData} from '../../../datas/LocalityData';

@Component({
  selector: 'app-vantilator-details',
  templateUrl: './vantilator-details.component.html',
  styleUrls: ['./vantilator-details.component.css']
})
export class VantilatorDetailsComponent implements OnInit {
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  localityName: string;
  ventilatorData: Array<VentilatorData> = new Array<VentilatorData>();

  constructor(private router: Router, private localService: LocalStorageService,
              private remoteService: RemoteCheckService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.localService.getOnDocumentWithCode(ItemData.LOCALITY, this.currentZoneCode).then((locality: LocalityData) => {
      this.localityName = locality.generalLocalityData.villageName4;
      this.localService.getAllData(ItemData.VENTILATOR).then((ventilators: Array<VentilatorData>) => {
        this.ventilatorData = ventilators.filter(value => value.localityCode === this.currentZoneCode);
      });
      this.spinner.hide();
    });
  }

  goToLocalityList(): void {
    this.router.navigate(['zone', 'list']);
  }

  onEditVentilator(ventilator: VentilatorData): void {
    localStorage.setItem(ItemData.CURRENT_VENTILATOR, ventilator.code);
    this.router.navigate(['ventilator', 'new']);
  }

  onNewVentilator(): void {
    this.router.navigate(['ventilator', 'new']);
  }
}
