<div class="parent mt-5 mb-5">
  <div class="container"
       fxLayout="column"
       fxLayoutAlign="space-between"
       fxLayoutGap="50px"
  >
    <div class=""
         fxFlex="50%">
      <img src="assets/images/prospex.png" class="responsive-item" alt="Prospection" style="cursor: pointer" (click)="goToProspectingData()">
      <button mat-raised-button
              class="upowa-color-last responsive-item"
              (click)="goToProspectingData()">
        Enquête de prospection
      </button>
    </div>
    <div class=""
         fxFlex="50%">
      <img src="assets/images/ventilo.jpg" class="responsive-item" alt="Ventillateur"  style="cursor: pointer" (click)="goToVentilatorData()">
      <button mat-raised-button
              class="upowa-color-last responsive-item"
              (click)="goToVentilatorData()">
        Enquête Ventillateur
      </button>
    </div>
  </div>
</div>

