<div class="container-fluid">
  <h5 class="form-title">Evènement dans la localité</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="eventForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>30 - Y a-t-il des événements dans la localité ?</mat-label>
        <mat-select formControlName="isEventInLocality30">
          <mat-option *ngFor="let isEvent of isEventList" [value]="isEvent">{{isEvent}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>31 - De quel événement s’agit-il ?</mat-label>
        <mat-select multiple formControlName="event31" (selectionChange)="checkOtherChoice($event, 31)">
          <mat-select-trigger>
            {{eventInLocality.value ? eventInLocality.value[0] : ''}}
            <span *ngIf="eventInLocality.value?.length > 1" class="example-additional-selection">
              (+{{eventInLocality.value.length - 1}} {{eventInLocality.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let event of eventsList" [value]="event">{{event}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest31">
        <mat-label>31 - Autres évènement ?</mat-label>
        <input matInput formControlName="otherEvent31" type="text">
      </mat-form-field>
      <div formGroupName="eventPeriod32" class="pb-2" *ngIf="isEvent.value === 'Oui'">
        <p class="px-3">32 - Si oui, indiquer la période ?</p>
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel *ngFor="let ePeriod of eventsInLocality">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ePeriod.text}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline"  class="form-element">
              <mat-label>{{ePeriod.text}}</mat-label>
              <mat-select multiple [formControlName]="ePeriod.name">
                <mat-option *ngFor="let period of periodList" [value]="period">{{period}}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >
        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                matTooltip="Back to previous step"
                matTooltipClass="example-tooltip-red"
                aria-label="Cancel"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!eventForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!eventForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
