export enum ItemData{
  USER = 'email',
  UPDATE = 'update',
  VERSION = 'V1.3.0',
  CURRENT_ZONE = 'current_zone',
  ZONE_COUNTER = 'zone_counter',
  ZONE_INPROGRESS = 'zone_inprogress',
  LOCALITY = 'locality',
  PROSPECT = 'prospect',
  VENTILATOR = 'ventilator',
  CURRENT_PROSPECT = 'current_prospect',
  CURRENT_VENTILATOR = 'current_ventilator'
}

