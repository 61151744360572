<div class="container-fluid">
  <h5 class="form-title">Recencement général des menages</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="generalForm" (submit)="onSubmit()">
      <div *ngIf="useGroupElect">
        <p class="mx-auto">Pour utilisateur de groupes électrogène</p>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>2 - Combien de litre de gasoil utilisez-vous par semaine ?</mat-label>
          <mat-select formControlName="fuelPerWeek2">
            <mat-option *ngFor="let item of fuelPerWeekList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>3 - A combien vous revient le litre de gasoil ?</mat-label>
          <mat-select formControlName="fuelLitterCost3">
            <mat-option *ngFor="let item of fuelLitterCostList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="useStack">
        <p class="mx-auto">Pour utilisateur de torche artisanale</p>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>4 - Combien de piles utilisez-vous/semaine ?</mat-label>
          <mat-select formControlName="stackUsePerWeek4">
            <mat-option *ngFor="let item of stackUsePerWeekList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>5 - A combien vous revient une pile ?</mat-label>
          <mat-select formControlName="stackCost5">
            <mat-option *ngFor="let item of stackCostList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div  *ngIf="usePetroleum">
        <p class="mx-auto">Pour utilisateur de lampe pétrole</p>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>6 - Combien de litre de pétrole consommez-vous/semaine ?</mat-label>
          <mat-select formControlName="petroleumLitterPerWeek6">
            <mat-option *ngFor="let item of petroleumLitterPerWeekList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>7 - A combien achetez-vous le litre de pétrole ?</mat-label>
          <mat-select formControlName="petroleumLitterCost7">
            <mat-option *ngFor="let item of petroleumLitterCostList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div  *ngIf="useCandles">
        <p class="mx-auto">Pour utilisateur de bougies</p>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>8 - Combien de bougies utilisez-vous/semaine ?</mat-label>
          <mat-select formControlName="candlesUsePerWeek8">
            <mat-option *ngFor="let item of candlesUsePerWeekList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>9 - Combien dépensez-vous par semaine pour acheter des bougies ?</mat-label>
          <mat-select formControlName="candlesUseCostPerWeek9">
            <mat-option *ngFor="let item of candlesUseCostPerWeekList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="useEneo">
        <p class="mx-auto">Pour Utilisateur electricité ENEO</p>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>10 - Combien de coupure d’électricité enregistrez-vous par semaine ?</mat-label>
          <mat-select formControlName="electricityCutPerWeek10">
            <mat-option *ngFor="let item of electricityCutPerWeekList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>11 - Quelle est la durée moyenne (en minute/heure) d’une coupure d’électricité ?</mat-label>
          <mat-select formControlName="electricityCutDuration11">
            <mat-option *ngFor="let item of electricityCutDurationList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element">
          <mat-label>12 - A combien s’élève le montant de vos factures d’électricité/mois ?</mat-label>
          <mat-select formControlName="electricityBillCostPerMonth12">
            <mat-option *ngFor="let item of electricityBillCostPerMonthList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <hr>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>13 - Avez-vous un téléphone portable ?</mat-label>
        <mat-select formControlName="havePhone13">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline"  class="form-element" *ngIf="havePhone.value === ouiNonList[0]">
        <mat-label>14 - Si oui, combien de téléphones y a-t-il dans le ménage ?</mat-label>
        <mat-select formControlName="howManyPhone14">
          <mat-option *ngFor="let item of howManyPhoneList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline"  class="form-element" *ngIf="havePhone.value === ouiNonList[0]">
        <mat-label>15 - Par quelle source d’énergie rechargez- vous votre téléphone ?</mat-label>
        <input matInput formControlName="chargingPhone15" type="text">
      </mat-form-field>

      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>17 - Avez-vous déjà entendu parlé de la marque  UpOwa ?</mat-label>
        <mat-select formControlName="knowUpowa17">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <p *ngIf="nkowUpowa.value === ouiNonList[1]">19 - Si non, présenter l'entreprise au prospect avec flyers à l'appui</p>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="nkowUpowa.value === ouiNonList[0]">
        <mat-label>18 - Si oui,comment avez-vous connu cette marque ?</mat-label>
        <mat-select multiple formControlName="howKnowUpowa18" (selectionChange)="checkOtherChoice($event, 18)">
          <mat-select-trigger>
            {{howKnowUpowa.value ? howKnowUpowa.value[0] : ''}}
            <span *ngIf="howKnowUpowa.value?.length > 1" class="example-additional-selection">
              (+{{howKnowUpowa.value.length - 1}} {{howKnowUpowa.value?.length === 2 ? 'autre' : 'autres'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of howKnowUpowaList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" *ngIf="otherQuest18">
        <mat-label>9 - Autres moyens de locomotion ?</mat-label>
        <input matInput formControlName="otherHowKnowUpowa18" type="text">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>20 - Seriez-vous intéressé par les solutions à énergie solaire proposées par UpOwa ?</mat-label>
        <mat-select formControlName="interestedByUpowaProduct20">
          <mat-option *ngFor="let item of ouiNonList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>21 - Si oui, à quelle période ?</mat-label>
        <mat-select formControlName="interestedPeriod21">
          <mat-option *ngFor="let item of interestedPeriodList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>22 - Avez-vous de remarques ou suggestion liées à ce type d’appareils ?</mat-label>
        <textarea matInput formControlName="remark22" style="height: 100px"></textarea>
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50" type="reset"
                (click)="onBack()"
                color="warn"
                class="responsive-item">
          Previous Step
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!generalForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!generalForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
