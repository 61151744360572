import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage.service';
import {ItemData} from '../../../datas/ItemData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {VentilatorData} from '../../../datas/VentilatorData';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-ventilator-form',
  templateUrl: './ventilator-form.component.html',
  styleUrls: ['./ventilator-form.component.css']
})
export class VentilatorFormComponent implements OnInit {

  ventilatorForm: FormGroup;
  currentZoneCode = localStorage.getItem(ItemData.CURRENT_ZONE);
  currentVentilatorCode = localStorage.getItem(ItemData.CURRENT_VENTILATOR);

  forUpdate: boolean;
  beginHour: string;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private router: Router, private localService: LocalStorageService) {
    this.forUpdate = isNotNullOrUndefined(this.currentVentilatorCode);
    const date = new Date();
    this.beginHour = date.getHours() + 'H : ' + date.getMinutes() + 'M';
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
    if (this.forUpdate) {
      this.spinner.show();
      this.pathValueInForm();
    }
  }

  initForm(): void {
    this.ventilatorForm = this.formBuilder.group({
        fullName: [null, Validators.required],
        village: [null, Validators.required],
        surveyType: [null, Validators.required]
      }
    );
    this.spinner.hide();
  }

  private pathValueInForm(): void {
    this.localService.getOnDocumentWithCode(ItemData.VENTILATOR, this.currentVentilatorCode).then((value: VentilatorData) => {
      if (isNotNullOrUndefined(value)) {
        this.ventilatorForm.patchValue(
          {fullName: value.fullName, village: value.village, surveyType: value.surveyType}
        );
      }
      this.spinner.hide();
    });
  }

  onSubmit(): void {
    if (this.forUpdate) {
      this.onUpdate();
    } else {
      const formValue = this.ventilatorForm.value;

      const ventilator: VentilatorData = {
        code: uuidv4(),
        localityCode: this.currentZoneCode,
        fullName: formValue.fullName,
        village: formValue.village,
        date: new Date(),
        updatedAt: new Date(),
        beginHour: this.beginHour,
        endHour: null,
        surveyType: formValue.surveyType,
        customerIdentification1: null,
        ventilator2: null,
        ventilatorUsage3: null,
        ventilatorProposition4: null,
        priceEvaluation5: null,
        cashPayment6: null,
        cashPaymentFifty7: null,
        kitOffer8: null,
        zone: null,
        shopVsTraders9: null,
        shopAndOthers10: null,
        upowaLink11: null,
        synchronised: false
      };
      this.localService.addDocumentOnCollection(ItemData.VENTILATOR, ventilator).then((value: any) => {
        const  data = value.data.data as VentilatorData;
        localStorage.setItem(ItemData.CURRENT_VENTILATOR, data.code);
        this.router.navigate(['ventilator', 'customer']);
        this.spinner.hide();
      });
    }
  }

  private onUpdate(): void {
    this.spinner.show();
    const formValue = this.ventilatorForm.value;
    const data: any = {
      fullName: formValue.fullName,
      village: formValue.village,
      surveyType: formValue.surveyType,
      updatedAt: new Date()
    };

    this.localService.updateDocumentOnCollection(ItemData.VENTILATOR, this.currentVentilatorCode, data).then(value => {
      // call update backend endpoint for the request
      this.spinner.hide();
      this.router.navigate(['ventilator', 'customer']);
    });
  }

  cancel(): void {
    this.initForm();
    localStorage.removeItem(ItemData.CURRENT_VENTILATOR);
    this.router.navigate(['ventilator', 'detail']);
  }

  backToList(): void {
    localStorage.removeItem(ItemData.CURRENT_VENTILATOR);
    this.router.navigate(['ventilator', 'detail']);
  }
}
