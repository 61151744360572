<div class="container-fluid">
  <h5 class="form-title">Informations sur l'enquêteur</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="ventilatorForm" (submit)="onSubmit()">
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Nom de l'enquêteur</mat-label>
        <input matInput type="text" formControlName="fullName">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Village, arrondissement</mat-label>
        <input matInput type="text" formControlName="village">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element" >
        <mat-label>Quelle enquête voulez vous effectuer ?</mat-label>
        <mat-select formControlName="surveyType">
          <mat-option value="SURVEY_A">ENQUÊTE A</mat-option>
          <mat-option value="SURVEY_B">ENQUÊTE B</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="reset"
                (click)="backToList()"
                color="warn"
                class="responsive-item">
          Back To List
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="reset"
                (click)="cancel()"
                color="warn"
                class="responsive-item">
          Cancel
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!ventilatorForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!ventilatorForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
