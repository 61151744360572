<div class="container-fluid">
  <h5 class="form-title">Informations sur l'enquêteur</h5>
  <div class="form-container responsive-item" style="top: 100px">
    <form class="form" [formGroup]="zoneForm" (submit)="onSubmit()">
      <mat-form-field appearance="legacy"  class="form-element">
        <mat-label>N° du questionnaire</mat-label>
        <input matInput formControlName="surveyNumber" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Date de l'enquête</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="surveyDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Nom de l'enquêteur</mat-label>
        <input matInput type="text" formControlName="investigator">
      </mat-form-field>
      <mat-form-field appearance="outline"  class="form-element">
        <mat-label>Numéro de l'enquêteur</mat-label>
        <input matInput type="number" formControlName="investigatorPhone">
      </mat-form-field>

      <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="reset"
                (click)="backToList()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                color="warn"
                class="responsive-item">
          Back To List
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="reset"
                (click)="cancel()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                color="warn"
                class="responsive-item">
          Cancel
        </button>
        <button *ngIf="!forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!zoneForm.valid">
          Next Step
        </button>
        <button *ngIf="forUpdate" mat-raised-button fxFlex="50" type="submit"
                class="upowa-color-last responsive-item"
                [disabled]="!zoneForm.valid">
          Update -> Next
        </button>
      </div>
    </form>
  </div>
</div>
