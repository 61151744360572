import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RequestCacheService} from './request-cache.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    console.log('I`\'m in HTTP interceptor');
    console.log(req.url);
    const cachedResponse = this.cache.get(req);
    return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler,
              cache: RequestCacheService): Observable<HttpEvent<any>> {
    return next.handle(req).pipe( tap(event => {
        if (event instanceof HttpResponse) {
          console.log('I\'m in HTTP interceptor cash push');
          console.log(req.url);
          const backendUrl = req.url;
          console.log(backendUrl.includes(environment.backendUrl));
          console.log(environment.backendUrl);
          if (backendUrl.includes(environment.backendUrl)) {
            console.log(req.url);
            console.log('Backend url');
          } else {
            console.log('In the non backend url for push');
            cache.put(req, event);
          }
        }
      })
    );
  }
}
