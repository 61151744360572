<div class="container-fluid" >
  <h5 class="form-title">Zones déjà enregistrées</h5>
  <mat-grid-list cols="5" rowHeight="100px" *ngFor="let locality of localitiesData">
    <mat-grid-tile colspan="4">
      <mat-card style="width: 98%; padding: 13px; height: 50px" fxHide.xs="true">
        <span> <strong>Region: </strong> {{locality?.generalLocalityData?.regionName1}}</span> <span class="mx-2">|</span>
        <span><strong>Ville: </strong> {{locality?.generalLocalityData?.villageName4}}</span>
      </mat-card>
      <mat-card style="width: 98%; padding: 13px;" fxLayout fxLayout.xs="column" fxLayoutGap="5"  fxHide.md="true" fxHide.sm="true" fxHide.lg="true" fxHide.xl="true">
        <span> <strong>Region: </strong> {{locality?.generalLocalityData?.regionName1}}</span><span class="mx-2" fxHide.xs="true">|</span>
        <span><strong>Ville: </strong> {{locality?.generalLocalityData?.villageName4}} </span>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-icon-button (click)="onEditLocality(locality)">
        <mat-icon color="warn"> edit</mat-icon>
      </button>
      <button mat-icon-button (click)="onGoChooseQuestion(locality)">
        <mat-icon class="green-color">remove_red_eye</mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="mgs-top" *ngIf="localitiesData.length <= 0">
    <img class="responsive-item img-padding" src="assets/images/nodata.png">
  </div>
</div>
